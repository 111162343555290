import React from 'react';
import { ClickAwayListener, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Done, Edit } from '@material-ui/icons';
import classNames from 'classnames';
import { useFocus } from '../../custom-hooks';

interface Props {
  initialValue?: string;
  className?: string;
  onChange?: (previousValue: string, newValue: string) => void;
}

const EditableTextField = ({ initialValue, onChange, className }: Props) => {

    const defaultValue = initialValue || "";

    const classes = useStyles();
    const [ref, setFocus] = useFocus();
    const [value, setValue] = React.useState(defaultValue);
    const [editMode, setEditMode] = React.useState(false);

    React.useEffect(() => {
        if (editMode) {
            setFocus();
        }
    }, [setFocus, editMode]);

    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue, setValue]);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleClick = () => {
        if (editMode && onChange && value !== defaultValue) {
            onChange(defaultValue, value);
        }
        setEditMode(!editMode);
    };

    const discardChanges = () => {
        if (defaultValue !== value) {
            setValue(defaultValue);
        }
        setEditMode(false);
    };

    return (
      <ClickAwayListener onClickAway={discardChanges}>
          <TextField
            inputRef={ref}
            className={classNames(classes.field, className)}
            name="email"
            margin="normal"
            onChange={handleChange}
            value={value}
            autoFocus={true}
            disabled={!editMode}
            InputProps={{
                classes: {
                    root: classes.root,
                    disabled: classes.disabled
                },
                endAdornment: (
                  <InputAdornment className={classes.adornment} position="end">
                      <IconButton size={"small"} onClick={handleClick}>
                          {editMode ? <Done fontSize={"small"} /> : <Edit fontSize={"small"} />}
                      </IconButton>
                  </InputAdornment>
                )
            }}
          />
      </ClickAwayListener>
    )
};

const useStyles = makeStyles(theme => ({
    adornment: {
        visibility: 'hidden',
    },
    disabled: {
        color: theme.palette.common.black,
    },
    field: {
        margin: 0,
        '&:hover $adornment': {
            visibility: 'visible'
        }
    },
    root: {
        fontSize: theme.typography.body2.fontSize,
    }
}));

export default EditableTextField;
