import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import {Loading, useQuery} from "react-admin";
import {useTheme} from "@material-ui/styles";
import {Typography} from "@material-ui/core";

const SubscriptionChart = (props) => {

    const theme = useTheme();

    const {data, loading, error} = useQuery({
        type: 'fetchStatistics',
        resource: 'subscription',
    }, {});

    if (loading)
        return <Loading/>

    if (error) {
        return <Typography color={'error'}>{error.message}</Typography>
    }

    return (

        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
                <XAxis
                    style={{
                        fontFamily: 'Roboto',
                    }}
                    dataKey="subscriptionCount"
                    name="# Subscriptions"
                    type="number"
                    scale="linear"
                />
                <YAxis
                    style={{
                        fontFamily: 'Roboto',
                    }}
                    dataKey="userCount"
                    name="Users"/>
                <CartesianGrid strokeDasharray="3 3"/>
                <Tooltip
                    cursor={{strokeDasharray: '3 3'}}
                    formatter={value => `${value} User`}
                    labelFormatter={label => `${label} Subscriptions`}
                />
                <Bar dataKey="userCount" fill={theme.palette.secondary.main}/>
            </BarChart>
        </ResponsiveContainer>
    )
}

export default SubscriptionChart;
