enum CopyrightStatus {
  UNCLEAR = 'unclear',
  TEXT_UNCLEAR_IMAGE_APPROVED = 'textUnclearImageApproved',
  TEXT_APPROVED_IMAGE_UNCLEAR = 'textApprovedImageUnclear',
  APPROVED = 'approved',
  RISK = 'risk',
  ACCEPTABLE_RISK = 'acceptableRisk',
  NEGATIVE = 'negative',
}

export default CopyrightStatus;
