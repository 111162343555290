import React from 'react';
import { SelectInput } from 'react-admin';
import TopicType from '../../topicType';

const TopicTypeInput = (props: React.ComponentProps<typeof SelectInput>) => (
  <SelectInput
    {...props}
    choices={[
      { id: TopicType.INDEX_BASED, name: `resources.daily.${TopicType.INDEX_BASED}` },
      { id: TopicType.DATE_BASED, name: `resources.daily.${TopicType.DATE_BASED}` },
      { id: TopicType.QUIZ, name: `resources.daily.${TopicType.QUIZ}` },
    ]}
  />
);

TopicTypeInput.defaultProps = {
  allowEmpty: false,
};

export default TopicTypeInput;
