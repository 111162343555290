import * as React from 'react';
import get from 'lodash/get';
import { sanitizeFieldRestProps, FieldProps } from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

export interface ImageFieldProps extends FieldProps {
  src?: string;
  title?: string;
  filename?: string;
  classes?: object;
}

const ImageField: React.FC<ImageFieldProps> = props => {
  const { className, classes: classesOverride, emptyText, filename, record, source, src, title, ...rest } = props;

  const sourceValue = get(record, source);
  const classes = useStyles(props);
  if (!sourceValue) {
    return emptyText ? (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul className={classnames(classes.list, className)} {...sanitizeFieldRestProps(rest)}>
        {sourceValue.map((file, index) => {
          const fileFilenameValue = get(file, filename) || filename;
          const srcValue = get(file, src) || title;

          return (
            <li key={index}>
              <Image src={srcValue} filename={fileFilenameValue} className={classes.image} />
            </li>
          );
        })}
      </ul>
    );
  }

  const filenameValue = get(sourceValue, filename) ?? get(record, filename) ?? filename;
  const srcValue = get(sourceValue, src) ?? sourceValue;

  return (
    <div className={className} {...sanitizeFieldRestProps(rest)}>
      <Image src={srcValue} filename={filenameValue} className={classes.image} />
    </div>
  );
};

interface ImageProps {
  src: string;
  filename: string;
  className?: string;
}

const Image = (props: ImageProps) => {

  const classes = useStyles();

  return (
    <div style={{ position: 'relative' }}>
      <a className={classes.filenameLink} href={props.src} target={'_blank'} rel="noopener noreferrer" download>
        {props.filename}
      </a>
      <img alt={""} src={props.src} className={props.className} />
    </div>
  );
};

const useStyles = makeStyles(theme => createStyles(
  {
    list: {
      display: 'flex',
      listStyleType: 'none',
    },
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
    },
    filenameLink: {
      position: 'absolute',
      top: theme.spacing(1.5),
      left: theme.spacing(1.5),
      fontSize: 10,
    },
  }),
  { name: 'RaImageField' }
);

// wat? TypeScript looses the displayName if we don't set it explicitly
ImageField.displayName = 'ImageField';

ImageField.defaultProps = {
  addLabel: true,
};

export default ImageField;
