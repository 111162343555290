import React from 'react';
import { DeleteButton, SaveButton, Toolbar } from 'react-admin';
import { useCheckAbilities } from '../custom-hooks';
import { Subject } from '../appAbilities';
import Spacer from './Spacer';

type ToolbarProps = React.ComponentProps<typeof Toolbar>;

type Props = {
  subject: Subject;
  renderRight?: (props: ToolbarProps) => React.ReactNode;
} & ToolbarProps;

const AuthorizedToolbar = ({ subject, renderRight, ...props }: Props) => {
  const [canSave, canDelete] = useCheckAbilities(can => [can('update', subject), can('delete', subject)]);

  return (
    <Toolbar {...props}>
      {canSave && <SaveButton disabled={props.pristine} />}
      <Spacer />
      {renderRight && renderRight(props)}
      {props.record && typeof props.record.id !== 'undefined' && canDelete && (
        <DeleteButton mutationMode={props.mutationMode} />
      )}
    </Toolbar>
  );
};

export default AuthorizedToolbar;
