import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, ButtonProps, GetOneResult, Identifier, useMutation, useNotify, useTranslate } from 'react-admin';
import * as React from 'react';
import { CircularProgress, Theme } from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

interface Props extends ButtonProps {
  dailyId: number | string;
  topicId: number | string;
  direction: 'next' | 'prev';
}

const NeighborDailyButton = ({ dailyId, topicId, direction, ...rest }: Props) => {
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();
  const t = useTranslate();

  const onSuccess = (response: GetOneResult<{ id: Identifier | null }>) => {
    if (response.data.id) {
      history.push({
        pathname: `/daily/${encodeURIComponent(response.data.id)}`,
        search: topicId ? `cameFromTopic=${topicId}` : undefined,
      });
    } else {
      notify(t('resources.daily.neighbor.no_more'));
    }
  };

  const [fetchNeighbor, { data, loading }] = useMutation(
    {
      type: 'getNeighbor',
      resource: 'daily-to-topic',
      payload: { parentId: topicId, childId: dailyId, direction },
    },
    {
      onSuccess,
      onFailure: error => notify(error.message, 'warning'),
    }
  );

  return (
    <Button
      disabled={loading || data?.id === null}
      alignIcon={direction === 'next' ? 'right' : 'left'}
      label={`resources.daily.neighbor.${direction}`}
      onClick={fetchNeighbor}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={14} thickness={2} className={classes.rightIcon} />
      ) : direction === 'next' ? (
        <NavigateNext />
      ) : (
        <NavigateBefore />
      )}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
  })
);

export default NeighborDailyButton;
