import React from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface Props {
    className?: string;
    text: React.ReactNode;
}

const InfoNotice = ({ text, className }: Props) => {
    const classes = useStyles();
    return (
      <div className={classNames(classes.alert, className)}>
          <Typography variant={'caption'} color={'textSecondary'}>{text}</Typography>
      </div>
    );
}


const useStyles = makeStyles((theme) => createStyles({
    alert: {
        padding: theme.spacing(0.5, 1),
    }
}));

export default InfoNotice;
