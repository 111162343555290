import {
  AutocompleteArrayInput,
  DateField,
  ReferenceArrayInput,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import React from 'react';
import { AuthorizedToolbar, BelongsToMany } from '../common/custom-components';
import { SimpleFormProps } from 'ra-ui-materialui/lib/form/SimpleForm';
import AdminRole from '../common/adminRole';
import { useCheckAbilities } from '../common/custom-hooks';
import { titleWithFlagRenderer } from '../common/optionRendererFactory';

interface Props extends Omit<SimpleFormProps, 'children'>, ResourceComponentProps {}

const AdminForm = (props: Props) => {
  const [canUpdateRole, canUpdateTopics, canUpdateInitials] = useCheckAbilities(can => [
    can('update', 'Admin', 'role'),
    can('update', 'Admin', 'topicIds'),
    can('update', 'Admin', 'initials'),
  ]);

  return (
    <SimpleForm {...props} toolbar={<AuthorizedToolbar subject={'Admin'} />}>
      <TextField source={'id'} />
      <DateField source={'createdAt'} />
      <TextField source={'email'} />
      <TextInput source={'initials'} disabled={!canUpdateInitials} />
      <SelectInput
        disabled={!canUpdateRole}
        source={'role'}
        choices={Object.values(AdminRole).map(role => ({
          id: role,
          name: `resources.admin.roles.${role}`,
        }))}
      />
      {canUpdateTopics && (
        <ReferenceArrayInput source={'topicIds'} reference={'topic'}>
          <AutocompleteArrayInput optionText={titleWithFlagRenderer} />
        </ReferenceArrayInput>
      )}
      <BelongsToMany
        addLabel={!canUpdateTopics}
        source={'topicIds'}
        reference={'topic'}
        optionRenderer={titleWithFlagRenderer}
      />
    </SimpleForm>
  );
};

export default AdminForm;
