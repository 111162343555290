import {BooleanField} from "react-admin";
import React from 'react';

const IsDefinedField = ({record = {}, source, ...other}) => {

    let recordCopy = {
        ...record,
        [source]: Boolean(record[source])
    };

    return <BooleanField {...other} record={recordCopy} source={source}/>
};

export default IsDefinedField;
