import React, { ForwardedRef } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function CategoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none">
        <path d="M13.952,20.312 C14.784,20.312 15.644,20.208 16.532,20 C17.42,19.792 18.184,19.496 18.824,19.112 L18.824,19.112 L17.168,15.848 C16.752,16.104 16.28,16.316 15.752,16.484 C15.224,16.652 14.664,16.736 14.072,16.736 C13.048,16.736 12.192,16.312 11.504,15.464 C10.816,14.616 10.472,13.424 10.472,11.888 C10.472,10.4 10.8,9.204 11.456,8.3 C12.112,7.396 12.928,6.944 13.904,6.944 C14.448,6.944 14.944,6.988 15.392,7.076 C15.84,7.164 16.496,7.36 17.36,7.664 L17.36,7.664 L18.656,4.712 C18.016,4.28 17.26,3.948 16.388,3.716 C15.516,3.484 14.672,3.368 13.856,3.368 C11.44,3.368 9.52,4.164 8.096,5.756 C6.672,7.348 5.96,9.4 5.96,11.912 C5.96,14.376 6.628,16.392 7.964,17.96 C9.3,19.528 11.296,20.312 13.952,20.312 Z" />
      </g>
    </SvgIcon>
  );
}

export function SubcategoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none">
        <path d="M11.408,20.312 C13.424,20.312 15.012,19.856 16.172,18.944 C17.332,18.032 17.912,16.888 17.912,15.512 C17.912,14.184 17.376,13.072 16.304,12.176 C15.232,11.28 14.12,10.536 12.968,9.944 C12.296,9.592 11.716,9.284 11.228,9.02 C10.74,8.756 10.496,8.448 10.496,8.096 C10.496,7.792 10.64,7.524 10.928,7.292 C11.216,7.06 11.608,6.944 12.104,6.944 C12.648,6.944 13.144,6.988 13.592,7.076 C14.04,7.164 14.696,7.36 15.56,7.664 L15.56,7.664 L16.856,4.712 C16.216,4.28 15.46,3.948 14.588,3.716 C13.716,3.484 12.872,3.368 12.056,3.368 C10.2,3.368 8.728,3.812 7.64,4.7 C6.552,5.588 6,6.704 5.984,8.048 C5.984,9.44 6.528,10.572 7.616,11.444 C8.704,12.316 9.792,13.024 10.88,13.568 C11.504,13.872 12.08,14.192 12.608,14.528 C13.136,14.864 13.4,15.176 13.4,15.464 C13.4,15.768 13.22,16.056 12.86,16.328 C12.5,16.6 11.976,16.736 11.288,16.736 C10.696,16.736 10.02,16.628 9.26,16.412 C8.5,16.196 7.912,15.96 7.496,15.704 L7.496,15.704 L6.056,18.992 C6.696,19.376 7.54,19.692 8.588,19.94 C9.636,20.188 10.576,20.312 11.408,20.312 Z" />
      </g>
    </SvgIcon>
  );
}

export function TopicIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none">
        <polygon points="14.408 20 14.408 7.112 19.616 7.112 19.208 3.68 5.24 3.68 5.24 7.112 10.04 7.112 10.04 20" />
      </g>
    </SvgIcon>
  );
}

export function DailyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none">
        <path d="M13.736,20 C16.024,20 17.896,19.26 19.352,17.78 C20.808,16.3 21.536,14.288 21.536,11.744 C21.536,9.424 20.88,7.5 19.568,5.972 C18.256,4.444 16.344,3.68 13.832,3.68 L13.832,3.68 L6.68,3.68 L6.68,20 L13.736,20 Z M13.448,16.568 L11.048,16.568 L11.048,7.112 L13.616,7.112 C14.72,7.112 15.564,7.548 16.148,8.42 C16.732,9.292 17.024,10.4 17.024,11.744 C17.024,13.36 16.68,14.568 15.992,15.368 C15.304,16.168 14.456,16.568 13.448,16.568 L13.448,16.568 Z" />
      </g>
    </SvgIcon>
  );
}

export const IndexBasedIcon = React.forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 64 57">
    <g id="index_based" fillRule="nonzero">
      <g id="ordered">
        <g id="Group">
          <g>
            <rect id="Rectangle" x="24" y="8.01454545" width="40" height="12.0218182" />
            <rect id="Rectangle" x="24" y="36.0654545" width="40" height="12.0218182" />
            <polygon
              id="Path"
              points="7.34231804 5.85937355 2.74932614 10.0686937 0 6.76858671 7.47169812 0.202047372 12 0.202047372 12 24.0436364 7.34231804 24.0436364"
            />
            <path
              d="M0,50.7921818 L8.9068826,42.5438789 C9.36032616,42.1208868 9.81376292,41.642245 10.2672065,41.1079393 C10.72065,40.5736336 10.9473684,39.9502863 10.9473684,39.2378789 C10.9473684,38.4364204 10.6666695,37.8075074 10.1052632,37.3511211 C9.54385684,36.8947352 8.88529392,36.6665455 8.12955464,36.6665455 C7.22266752,36.6665455 6.51552212,36.9559567 6.00809716,37.534788 C5.50067224,38.1136189 5.21457524,38.8260158 5.14979756,39.672 L0.291497976,39.3046665 C0.356275628,38.0802163 0.604586236,37.0171862 1.03643725,36.1155455 C1.46828826,35.2139046 2.04048227,34.4625485 2.75303644,33.8614545 C3.46559061,33.2603606 4.3022894,32.809547 5.26315788,32.509 C6.2240264,32.2084531 7.26585132,32.0581818 8.38866396,32.0581818 C9.4251064,32.0581818 10.3967566,32.2084531 11.3036437,32.509 C12.2105308,32.809547 12.9986471,33.2547951 13.6680162,33.8447576 C14.3373853,34.4347201 14.8609968,35.1749451 15.2388664,36.0654545 C15.616736,36.9559639 15.805668,37.9911659 15.805668,39.1710909 C15.805668,39.9280239 15.7300952,40.6125931 15.5789474,41.2248182 C15.4277995,41.8370433 15.2172753,42.3936034 14.9473684,42.8945153 C14.6774615,43.3954268 14.3589762,43.8573716 13.9919028,44.2803636 C13.6248295,44.7033557 13.2253733,45.11521 12.7935223,45.5159393 L5.82995952,51.6938182 L16,51.6938182 L16,56.1018182 L0,56.1018182 L0,50.7921818 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
));

export const DateBasedIcon = React.forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 66 64">
    <g id="date_based" transform="translate(-1.000000, 0.000000)" fillRule="nonzero">
      <path
        d="M30.895,51.872 L37.036,51.872 C38.416,51.872 39.589,50.768 39.589,49.319 L39.589,45.524 C39.589,44.144 38.485,42.971 37.036,42.971 L30.895,42.971 C29.515,42.971 28.342,44.075 28.342,45.524 L28.342,49.319 C28.411,50.768 29.515,51.872 30.895,51.872 Z"
        id="Path"
      />
      <path
        d="M15.094,51.872 L21.235,51.872 C22.615,51.872 23.788,50.768 23.788,49.319 L23.788,45.524 C23.788,44.144 22.684,42.971 21.235,42.971 L15.094,42.971 C13.714,42.971 12.541,44.075 12.541,45.524 L12.541,49.319 C12.541,50.768 13.645,51.872 15.094,51.872 Z"
        id="Path"
      />
      <path
        d="M46.765,51.872 L52.906,51.872 C54.286,51.872 55.459,50.768 55.459,49.319 L55.459,45.524 C55.459,44.144 54.355,42.971 52.906,42.971 L46.765,42.971 C45.385,42.971 44.212,44.075 44.212,45.524 L44.212,49.319 C44.212,50.768 45.385,51.872 46.765,51.872 Z"
        id="Path"
      />
      <path
        d="M30.895,38.072 L37.036,38.072 C38.416,38.072 39.589,36.968 39.589,35.519 L39.589,31.724 C39.589,30.344 38.485,29.171 37.036,29.171 L30.895,29.171 C29.515,29.171 28.342,30.275 28.342,31.724 L28.342,35.519 C28.411,36.899 29.515,38.072 30.895,38.072 Z"
        id="Path"
      />
      <path
        d="M15.094,38.072 L21.235,38.072 C22.615,38.072 23.788,36.968 23.788,35.519 L23.788,31.724 C23.788,30.344 22.684,29.171 21.235,29.171 L15.094,29.171 C13.714,29.171 12.541,30.275 12.541,31.724 L12.541,35.519 C12.541,36.899 13.645,38.072 15.094,38.072 Z"
        id="Path"
      />
      <path
        d="M46.765,38.072 L52.906,38.072 C54.286,38.072 55.459,36.968 55.459,35.519 L55.459,31.724 C55.459,30.344 54.355,29.171 52.906,29.171 L46.765,29.171 C45.385,29.171 44.212,30.275 44.212,31.724 L44.212,35.519 C44.212,36.899 45.385,38.072 46.765,38.072 Z"
        id="Path"
      />
      <path
        d="M58.564,6.47 L54.286,6.47 L54.286,9.23 C54.286,12.335 51.733,14.888 48.628,14.888 C45.523,14.888 42.97,12.335 42.97,9.23 L42.97,6.47 L25.03,6.47 L25.03,9.23 C25.03,12.335 22.477,14.888 19.372,14.888 C16.267,14.888 13.714,12.335 13.714,9.23 L13.714,6.47 L9.436,6.47 C4.882,6.47 1.225,10.127 1.225,14.681 L1.225,55.046 C1.225,59.531 4.882,63.257 9.436,63.257 L58.564,63.257 C63.049,63.257 66.775,59.6 66.775,55.046 L66.775,14.681 C66.775,10.127 63.118,6.47 58.564,6.47 Z M60.496,54.977 C60.496,56.012 59.668,56.84 58.633,56.84 L9.436,56.84 C8.401,56.84 7.573,56.012 7.573,54.977 L7.573,24.203 L60.496,24.203 L60.496,54.977 Z"
        id="Shape"
      />
      <path
        d="M19.372,12.404 C21.097,12.404 22.546,11.024 22.546,9.23 L22.546,3.986 C22.546,2.261 21.166,0.812 19.372,0.812 C17.647,0.812 16.198,2.192 16.198,3.986 L16.198,9.23 C16.198,11.024 17.578,12.404 19.372,12.404 Z"
        id="Path"
      />
      <path
        d="M48.628,12.404 C50.353,12.404 51.802,11.024 51.802,9.23 L51.802,3.986 C51.802,2.261 50.422,0.812 48.628,0.812 C46.903,0.812 45.454,2.192 45.454,3.986 L45.454,9.23 C45.523,11.024 46.903,12.404 48.628,12.404 Z"
        id="Path"
      />
    </g>
  </SvgIcon>
));

export const QuizIcon = React.forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 64 64">
    <path
      d="M63.912 57.025a6.888 6.888 0 0 1-6.887 6.895H6.885C3.083 63.92 0 60.834 0 57.025V6.891A6.889 6.889 0 0 1 6.885 0h50.14a6.887 6.887 0 0 1 6.887 6.891v50.134z"
      fill="#1b75bb"
    />
    <g fill="#fff">
      <path d="M21.898 7.299c-.456-1.198-1.42-1.876-2.719-1.924l-.124-.467l-.124.463c-1.296.033-2.26.713-2.719 1.912L8.697 26.155a2.38 2.38 0 0 0-.129.781c0 .974.699 1.83 1.676 2.076c1.284.363 2.417-.159 2.812-1.29l1.887-5.04h8.173l1.932 5.06c.322.872 1.107 1.415 2.05 1.415c.259 0 .518-.041.77-.121c1.021-.271 1.7-1.088 1.7-2.046c0-.314-.059-.589-.193-.866L21.898 7.297m-.359 11.11h-5.02l2.533-6.506l2.483 6.506" />
      <path d="M52.977 16.513c1.38-1.07 2.058-2.652 2.058-4.789c0-3.875-2.703-6.186-7.234-6.186h-6.502c-2.535 0-3.766 1.293-3.766 3.956v15.541c0 2.608 1.269 3.929 3.766 3.929h6.807c4.732 0 7.676-2.656 7.676-6.932c-.002-2.58-.964-4.449-2.805-5.519m-5.233 2.313c2.996 0 3.345 1.688 3.345 2.957c0 1.258-.343 2.931-3.289 2.931h-5.741v-5.888h5.685m-.084-4.225h-5.602V9.787h5.549c2.485 0 2.819 1.148 2.819 2.267c0 2.105-1.503 2.547-2.766 2.547" />
      <path d="M26.691 48.913c-1.343-.385-2.551.188-2.994 1.409c-.766 2.362-2.291 3.511-4.659 3.511c-3.571 0-5.879-3.043-5.879-7.752c0-4.798 2.255-7.777 5.879-7.777c2.264 0 3.55.869 4.457 3.047c.384.812 1.13 1.278 2.046 1.278c.369 0 .699-.058.971-.16c.76-.254 1.526-.981 1.526-2.037a2.4 2.4 0 0 0-.181-.923c-1.042-2.541-3.434-5.567-8.929-5.567c-6.275 0-10.488 4.869-10.488 12.11c0 7.392 4.194 12.169 10.682 12.169c5.935 0 8.255-4.053 9.113-6.484a2.38 2.38 0 0 0 .134-.78c0-1.087-.866-1.857-1.678-2.046" />
      <path d="M45.74 34.35h-4.906c-2.517 0-3.793 1.33-3.793 3.956v15.541c0 2.606 1.276 3.929 3.793 3.929h4.906c6.569 0 10.651-4.576 10.651-11.947c0-7.188-3.984-11.479-10.651-11.479m5.959 11.506c0 4.805-2.226 7.56-6.1 7.56l-3.977-.004l.002-14.729h4.058c3.988 0 6.02 2.414 6.02 7.173" />
    </g>
  </SvgIcon>
));
