import React from 'react';
import { Record, useTranslate } from 'react-admin';
import { ToBeCheckedAtField } from '../common/custom-components';
import { makeStyles, Typography } from '@material-ui/core';

interface Props {
  record: Record;
}

const ToBeCheckedAt = ({record}: Props) => {

    const t = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant={'body2'} className={classes.tbc}>
                {t('resources.topic.fields.toBeCheckedAt')}
            </Typography>
            <ToBeCheckedAtField source={'toBeCheckedAt'} record={record}/>
        </div>
    );

};

const useStyles = makeStyles(theme => ({
    tbc: {
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
    },
    container: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1)
    },
}));

export default ToBeCheckedAt;
