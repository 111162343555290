import {SimpleForm, TextInput} from "react-admin";
import React from "react";

const LabelForm = (props) => {
    return (
        <SimpleForm {...props}>
            <TextInput source={'abbreviation'}/>
            <TextInput source={'description'}/>
        </SimpleForm>
    );
};

export default LabelForm;
