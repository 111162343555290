import React from "react";
import {Layout} from 'react-admin';
import CustomAppBar from "./CustomAppBar";
import CustomMenu from "./CustomMenu";

const CustomLayout = (props) => <Layout
    {...props}
    menu={CustomMenu}
    appBar={CustomAppBar}
/>;

export default CustomLayout;
