import React from 'react';
import { Datagrid, ListProps, TextField } from 'react-admin';
import { List } from '../common/custom-components';

const LabelList = (props: ListProps) => (
  <List subject={'Label'} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="abbreviation" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export default LabelList;
