import * as React from 'react';
import { AdminUI, Loading, Resource } from 'react-admin';
import { AppAbility } from './common/appAbilities';
import { CategoryIcon, DailyIcon, SubcategoryIcon, TopicIcon } from './icons';
import { Assessment as AssessmentIcon, Label as LabelIcon, SupervisorAccount } from '@material-ui/icons';
import { AdminEdit, AdminList } from './admin';
import { CustomLayout } from './layout';
import { CategoryCreate, CategoryEdit, CategoryList } from './category';
import { SubcategoryCreate, SubcategoryEdit, SubcategoryList } from './subcategory';
import { TopicCreate, TopicEdit, TopicList } from './topic';
import { DailyCreate, DailyEdit, DailyList } from './daily';
import { LabelCreate, LabelEdit, LabelList } from './label';
import { SubscriptionList } from './subscriptions';
import { AuthProvider } from './provider';
import { Dashboard } from './dashboard';
import { theme } from './common/theme';
import { Route } from 'react-router-dom';
import { UserPanel } from './user';

interface IProps {
  authProvider: AuthProvider;
}

const usersRoute = <Route exact path="/users" component={UserPanel} />;

const Resources = ({ authProvider }: IProps) => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const updatePermissions = async () => {
      try {
        setLoading(true);
        await authProvider.checkAuth();
        await authProvider.fetchRules();
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    updatePermissions();
  }, [authProvider]);

  return loading ? (
    <Loading />
  ) : (
    <AdminUI layout={CustomLayout} dashboard={Dashboard} theme={theme} customRoutes={[usersRoute]}>
      {(abilities: AppAbility) => [
        <Resource
          name={'category'}
          icon={CategoryIcon}
          list={CategoryList}
          edit={CategoryEdit}
          create={abilities.can('create', 'Category') ? CategoryCreate : null}
        />,
        <Resource
          name={'subcategory'}
          icon={SubcategoryIcon}
          list={SubcategoryList}
          edit={SubcategoryEdit}
          create={abilities.can('create', 'Subcategory') ? SubcategoryCreate : null}
        />,
        <Resource
          name={'topic'}
          icon={TopicIcon}
          list={TopicList}
          edit={TopicEdit}
          create={abilities.can('create', 'Topic') ? TopicCreate : null}
        />,
        <Resource
          name={'daily'}
          icon={DailyIcon}
          list={DailyList}
          edit={DailyEdit}
          create={abilities.can('create', 'Daily') ? DailyCreate : null}
        />,
        <Resource
          name={'label'}
          icon={LabelIcon}
          list={LabelList}
          edit={abilities.can('update', 'Label') ? LabelEdit : null}
          create={abilities.can('create', 'Label') ? LabelCreate : null}
        />,
        <Resource
          name={'subscription'}
          icon={AssessmentIcon}
          list={abilities.can('view', 'Statistics') ? SubscriptionList : null}
        />,
        <Resource
          name={'admin'}
          icon={SupervisorAccount}
          list={abilities.can('view', 'Admin') ? AdminList : null}
          edit={abilities.can('update', 'Admin') ? AdminEdit : null}
        />,
        <Resource name={'favourite'} />,
        <Resource name={'language'} />,
        <Resource name={'daily-to-topic'} />,
      ]}
    </AdminUI>
  );
};
export default Resources;
