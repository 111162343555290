import messages from 'ra-language-english';
import ContentStatus from '../common/contentStatus';
import ToBeCheckedAt from '../common/toBeCheckedAt';
import TopicPurpose from '../common/topicPurpose';
import CopyrightStatus from '../common/copyrightStatus';
import AdminRole from '../common/adminRole';

export default {
  ra: {
    ...messages.ra,
    page: {
      ...messages.ra.page,
      dashboard: 'News',
    },
  },
  action: {
    link: 'Link',
    unlink: 'Unlink',
    search_title: 'title, #id or dd.mm.',
    search_fulltext: 'Fulltext-Search',
    search_fulltext_with_note: 'Fulltext-Search + Note',
    search_fulltext_only_note: 'Note',
    saved: 'Element updated',
    seen_by_user: 'Subscription duration',
    quiz_statistics: 'Quiz Statistics',
    rating_statistics: 'Ratings',
    add_new: 'Add new',
  },
  common: {
    contentStatus: {
      [ContentStatus.NONE]: '-',
      [ContentStatus.CHANGE_REQUIRED]: '1 - Change required',
      [ContentStatus.WORK_IN_PROGRESS]: '2 - Work-in-Progress',
      [ContentStatus.READY_TO_REVIEW]: '3 - Ready-to-Review',
      [ContentStatus.READY_TO_PUBLISH]: '4 - Ready-to-Publish',
    },
    copyright: {
      [CopyrightStatus.UNCLEAR]: '?',
      [CopyrightStatus.TEXT_UNCLEAR_IMAGE_APPROVED]: 'T? / P✓',
      [CopyrightStatus.TEXT_APPROVED_IMAGE_UNCLEAR]: 'T✓ / P?',
      [CopyrightStatus.APPROVED]: '✓',
      [CopyrightStatus.RISK]: 'Grey',
      [CopyrightStatus.ACCEPTABLE_RISK]: 'OK',
      [CopyrightStatus.NEGATIVE]: 'Negative',
    },
    toBeCheckedAt: {
      [ToBeCheckedAt.VERY_SOON]: '< 1 week',
      [ToBeCheckedAt.SOON]: 'in 1-4 weeks',
      [ToBeCheckedAt.FUTURE]: '> 4 weeks',
    },
    unlink_confirmation: 'Unlink #%{id}?',
    dashboard: {
      title: 'News',
    },
    updated: 'Element updated',
    translated_by: 'Translated by',
    translation_of: 'Translation of',
    go_to_translation: 'Go to translation',
    quiz: 'Related Quiz',
    copy_translate: 'Copy & Translate',
    copy_quizify: 'Copy & Quizify',
    copy: {
      without_children: 'Without Child-Entities',
      with_children: 'With Child-Entities',
      confirm: {
        title_without_children: '(without children)?',
        title_with_children: '(with children)?',
        content: 'This operation will create new entities in the database.',
      },
    },
    missing_permission: 'Missing permission',
    form_sections: {
      general: 'General',
      language: 'Language',
      content: 'Content',
      admin: 'Admin',
    },
    validation: {
      min_length: 'Must contain %{min} elements at least',
      max_length: 'Must only contain %{max} elements or less',
    },
  },
  resources: {
    category: {
      name: 'Category |||| Categories',
      fields: {
        id: 'ID',
        title: 'Title',
        createdAt: 'Created',
        updatedAt: 'Updated',
        languageId: 'Language',
        isPublished: 'Live',
        language: 'Lang',
        subcategories: 'Subcategories',
      },
    },
    subcategory: {
      name: 'Subcategory |||| Subcategories',
      create: 'Create Subcategory',
      fields: {
        categoryId: 'Belongs to category',
        createdAt: 'Created',
        updatedAt: 'Updated',
        id: 'ID',
        title: 'Title',
        isPublished: 'Live',
        languageId: 'Language',
        topics: 'Topics',
        language: 'Lang',
        categoryIds: 'Belongs to category',
      },
      go_to_category: 'Go to category',
    },
    topic: {
      name: 'Topic |||| Topics',
      create: 'Create Topic',
      fields: {
        createdAt: 'Created',
        updatedAt: 'Updated',
        isPublished: 'Live',
        order: 'Order',
        id: 'ID',
        title: 'Title',
        finalText: 'Final Text (last Daily)',
        languageId: 'Language',
        text: 'Text',
        note: 'Note',
        purpose: 'Purpose',
        subcategoryIds: 'Belongs to subcategories',
        contentStatus: 'Content-Status',
        imageCount: 'Images',
        labelIds: 'Labels',
        withoutLabelIds: 'Without Labels',
        language: 'Lang',
        toBeCheckedAt: 'Tbc',
        relatedTopicIds: 'Related topics',
        assignedAdminIds: 'Assigned admins',
        fullTextWithNote: 'Full Text + Note',
        fullTextOnlyNote: 'Note',
      },
      quiz_source_topic: 'Source Topic',
      nextUnassignedDate: 'Next unassigned date within next 30 days',
      purpose: {
        [TopicPurpose.DEFAULT]: 'Default (Subscribable)',
        [TopicPurpose.INSPIRATION]: 'Inspiration',
        [TopicPurpose.INSPIRATION_FALLBACK]: 'Fallback (Inspiration)',
        [TopicPurpose.NEWS]: 'News',
      },
    },
    daily: {
      name: 'Daily |||| Dailys',
      create: 'Create Daily',
      dateBased: 'Date-based',
      indexBased: 'Standard',
      quiz: 'Quiz',
      quiz_source_daily: 'Source Daily',
      fields: {
        createdAt: 'Created',
        updatedAt: 'Updated',
        imageCount: 'Images',
        id: 'ID',
        isPublished: 'Live',
        title: 'Title',
        teaser: 'Teaser',
        text: 'Full Text',
        solution: 'Solution',
        note: 'Note',
        copyright: 'Copyright',
        contentStatus: 'Content-Status',
        labelIds: 'Labels',
        withoutLabelIds: 'Without Labels',
        languageId: 'Language',
        topicIds: 'Belongs to topics',
        toBeCheckedAt: 'Tbc',
        language: 'Lang',
        isCorrect: 'Is Correct',
        choice: 'Quiz Choice',
        fullText: 'Full Text',
        fullTextWithNote: 'Full Text + Note',
        fullTextOnlyNote: 'Note',
      },
      confirmation: {
        title: 'Daily is live',
        message: 'Save your changes?',
      },
      neighbor: {
        prev: 'Previous',
        next: 'Next',
        no_more: 'No more dailies found',
      },
      validation: {
        exactly_one_correct: 'Exactly one choice must be correct',
      },
    },
    'daily-to-topic': {
      fields: {
        title: 'Title',
        teaser: 'Teaser',
        isPublished: 'Live',
        contentStatus: 'Status',
        imageCount: 'Images',
        toBeCheckedAt: 'Tbc',
      },
    },
    admin: {
      fields: {
        topicIds: 'Assigned Topics',
      },
      roles: {
        [AdminRole.ADMIN]: 'Admin',
        [AdminRole.MANAGER]: 'Manager',
        [AdminRole.PUBLISHER]: 'Publisher',
        [AdminRole.SENIOR_WRITER]: 'Senior Writer',
        [AdminRole.WRITER]: 'Writer',
        [AdminRole.READER]: 'Reader',
      },
    },
    subscription: {
      fields: {
        name: 'Subscription |||| Subscriptions',
        id: 'Topic',
        runningCount: 'Running',
        completedCount: 'Completed',
        canceledCount: 'Canceled',
      },
      stats: {
        title: 'Users & Subscriptions',
        subtitle: 'Only includes users with at least one subscription',
      },
      subscription_duration: {
        notice: 'Only includes subscriptions that were completed after change of daily logic (Oct/21)',
      },
      notice: 'Only includes users online 2x within last 30 days',
      quizStatistics: {
        dailyId: 'Daily Id',
        dailyTitle: 'Daily Title',
        correctAnswers: 'Correct',
        totalAnswers: 'Total',
        averageCorrect: 'Ø Avg',
        total: 'Total',
      },
      rating_statistics: {
        total: 'Total',
        daily_id: 'Daily Id',
        daily_title: 'Daily Title',
        positive: 'Positive',
        neutral: 'Neutral',
        negative: 'Negative',
        average_rating: 'Average',
        notice: 'Only includes dailies with at least one rating',
      },
    },
    favourite: {
      stats: {
        title: 'Users & Favourites',
        subtitle: 'Only includes users with at least one favourite',
      },
    },
    user: {
      title: 'App-User',
      stats: {
        title: 'Users by language',
        subtitle: 'Users online 2x within last 30 days',
        language: 'Language',
        count: 'Count',
        share: 'Share',
      },
      activeLastWeek: {
        title: 'Active Users',
        subtitle: 'Number of days where users were active within last 7 days',
      },
    },
  },
};
