import {BooleanField} from "react-admin";
import {Translate as TranslateIcon} from "@material-ui/icons";
import React from "react";

const IsTranslatedField = ({record = {}, ...other}) => {

    let recordCopy = {
        ...record,
        isTranslationOrHasTranslation: Boolean(record['translatedById']) || Boolean(record['translationOfId'])
    };

    return <BooleanField {...other} record={recordCopy} source={'isTranslationOrHasTranslation'}/>
};

IsTranslatedField.defaultProps = {
    label: <TranslateIcon />,
}

export default IsTranslatedField;
