import React from 'react';

type UseFocus = [React.MutableRefObject<HTMLElement | null>, () => void];

const useFocus = (): UseFocus => {
  const htmlElRef = React.useRef<HTMLElement | null>(null);
  const setFocus = React.useCallback(() => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  }, [htmlElRef]);

  return [htmlElRef, setFocus];
};

export default useFocus;
