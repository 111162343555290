import { Edit as ReactAdminEdit, EditProps } from 'react-admin';
import { ReactElement } from 'react';

const Edit = ReactAdminEdit as React.ComponentType<
  EditProps & {
    children: ReactElement;
  }
>;

Edit.defaultProps = {
  mutationMode: 'optimistic',
  undoable: false,
} as Partial<EditProps>;

export default Edit;
