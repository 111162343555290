import React from 'react';
import Edit from '../common/custom-components/Edit';
import AdminForm from './AdminForm';
import { EditProps } from 'react-admin';

const AdminEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <AdminForm redirect={false} />
    </Edit>
  );
};

export default AdminEdit;
