import { Record } from "react-admin";
import languageToFlag from "./languageToFlag";
import TopicType from "./topicType";

const quizSymbol = '🔠️';

export function createSimpleOptionRenderer(fieldName: string, addFlag = false, addQuizSymbol = false) {
  return (record: Record) => {
    if (record && record.id !== null) {
      let label = `${record[fieldName]} (#${record.id})`;
      if (addFlag && record.language) {
        const flag = Object.keys(languageToFlag).includes(record.language)
          ? (languageToFlag as any)[record.language]
          : "";
        label = label + " " + flag;
      }
      if (addQuizSymbol && record.type === TopicType.QUIZ) {
        label = label + " " + quizSymbol;
      }
      return label;
    }
    return "No record";
  };
}

export const titleWithFlagRenderer = createSimpleOptionRenderer("title", true, true);
export const titleRenderer = createSimpleOptionRenderer("title", false);
