import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  ListProps,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import {
  ChipField,
  ContentStatusField,
  CreateDailyDialButton,
  CustomListActions,
  FunctionChipField, HasQuizField,
  IsDefinedField,
  IsTranslatedField,
  LanguageField,
  List,
  TopicTypeField,
  TruncatedTextField
} from "../common/custom-components";
import { ImageOutlined as ImageIcon, LabelOutlined as LabelIcon } from '@material-ui/icons';
import TopicFilter from './TopicFilter';
import { titleRenderer } from '../common/optionRendererFactory';

export const TopicList = (props: ListProps) => {
  return (
    <List
      {...props}
      subject={'Topic'}
      applyGlobalLanguage={true}
      filters={<TopicFilter />}
      actions={<CustomListActions createButton={<CreateDailyDialButton />} />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source={'language'} reference={'language'}>
          <LanguageField />
        </ReferenceField>
        <TopicTypeField source={'type'} />
        <TextField source={'title'} />
        <TruncatedTextField source={'text'} />
        <ReferenceArrayField sortable={false} source="subcategoryIds" reference="subcategory">
          <SingleFieldList>
            <FunctionChipField source="title" render={titleRenderer} />
          </SingleFieldList>
        </ReferenceArrayField>
        <ContentStatusField source={'contentStatus'} />
        <ReferenceArrayField source={'labelIds'} reference="label" label={<LabelIcon />} sortable={false}>
          <SingleFieldList>
            <ChipField source={'abbreviation'} />
          </SingleFieldList>
        </ReferenceArrayField>
        <IsDefinedField sortable={false} source={'imageId'} label={<ImageIcon />} />
        <DateField source="updatedAt" />
        <TextField source="updatedBy" sortBy={'updatedBy.initials'} />
        <TruncatedTextField source={'note'} />
        <IsTranslatedField />
        <HasQuizField />
        <BooleanField source={'isPublished'} />
      </Datagrid>
    </List>
  );
};

export default TopicList;
