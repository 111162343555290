import { Box, Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import React from 'react';
import { NotFound, useTranslate } from 'react-admin';
import SubscriptionChart from './SubscriptionChart';
import UserCount from './UserCount';
import FavouriteChart from './FavouriteChart';
import UsersActiveChart from './UsersActiveChart';
import classnames from 'classnames';
import { useCheckAbilities } from '../common/custom-hooks';

interface Props {}

const UserPanel = (props: Props) => {
  const t = useTranslate();
  const classes = useStyles();
  const [canViewStatistics] = useCheckAbilities(can => [can('view', 'Statistics')]);

  return canViewStatistics ? (
    <Box>
      <Box display={'flex'} flexDirection={'row'}>
        <Card className={classes.container}>
          <CardHeader title={t('resources.user.stats.title')} subheader={t('resources.user.stats.subtitle')} />
          <CardContent>
            <UserCount />
          </CardContent>
        </Card>

        <Card className={classnames(classes.container, classes.grow)}>
          <CardHeader
            title={t('resources.user.activeLastWeek.title')}
            subheader={t('resources.user.activeLastWeek.subtitle')}
          />
          <CardContent>
            <UsersActiveChart />
          </CardContent>
        </Card>
      </Box>
      <Box display={'flex'} flexDirection={'row'}>
        <Card className={classnames(classes.container, classes.grow)}>
          <CardHeader
            title={t('resources.subscription.stats.title')}
            subheader={t('resources.subscription.stats.subtitle')}
          />
          <CardContent>
            <SubscriptionChart />
          </CardContent>
        </Card>
        <Card className={classnames(classes.container, classes.grow)}>
          <CardHeader
            title={t('resources.favourite.stats.title')}
            subheader={t('resources.favourite.stats.subtitle')}
          />
          <CardContent>
            <FavouriteChart />
          </CardContent>
        </Card>
      </Box>
    </Box>
  ) : <NotFound />;
};
const useStyles = makeStyles(theme => ({
  container: {
    padding: '1em',
    marginBottom: '1em',
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
}));

export default UserPanel;
