import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  ListProps,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import {
  ChipField,
  ContentStatusField,
  CreateDailyDialButton,
  CustomListActions, FunctionChipField, HasQuizField,
  IsTranslatedField,
  LanguageField,
  List,
  ToBeCheckedAtField,
  TopicTypeField,
  TruncatedTextField
} from "../common/custom-components";
import CopyrightField from './CopyrightField';
import { ImageOutlined as ImageIcon, LabelOutlined as LabelIcon } from '@material-ui/icons';
import DailyFilter from './DailyFilter';
import { makeStyles } from '@material-ui/core';
import { titleRenderer } from '../common/optionRendererFactory';

const DailyList = (props: ListProps) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      subject={'Daily'}
      applyGlobalLanguage={true}
      filters={<DailyFilter />}
      actions={<CustomListActions createButton={<CreateDailyDialButton />} />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source={'language'} reference={'language'}>
          <LanguageField />
        </ReferenceField>
        <TopicTypeField source={'type'} />
        <TextField className={classes.titleColumn} source={'title'} />
        <TruncatedTextField source={'teaser'} />
        <ReferenceArrayField sortable={false} source="topicIds" reference="topic">
          <SingleFieldList>
            <FunctionChipField source="title" render={titleRenderer} />
          </SingleFieldList>
        </ReferenceArrayField>
        <CopyrightField source={'copyright'} />
        <ContentStatusField source={'contentStatus'} />
        <NumberField label={<ImageIcon />} sortable={false} source={'imageCount'} />
        <DateField source={'date'} />
        <ReferenceArrayField label={<LabelIcon />} sortable={false} source={'labelIds'} reference="label">
          <SingleFieldList>
            <ChipField source={'abbreviation'} />
          </SingleFieldList>
        </ReferenceArrayField>
        <ToBeCheckedAtField source="toBeCheckedAt" />
        <DateField source="updatedAt" />
        <TextField source="updatedBy" sortBy={'updatedBy.initials'} />
        <TruncatedTextField source={'note'} />
        <IsTranslatedField />
        <HasQuizField />
        <BooleanField source={'isPublished'} />
      </Datagrid>
    </List>
  );
};

const useStyles = makeStyles({
  titleColumn: {
    display: 'block',
    minWidth: '100px',
  },
});

export default DailyList;
