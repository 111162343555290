import React from "react";
import {Confirm, useDataProvider, useNotify, useRefresh, useTranslate} from "react-admin";
import {LinkOff} from "@material-ui/icons";
import {IconButton, Tooltip} from "@material-ui/core";


const UnlinkButton = (props) => {
    const {record, parentResource, parentId} = props;
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();
    const [isLoading, setLoading] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleUnlink = event => {
        event.stopPropagation();
        setLoading(true);
        dataProvider
            .associate(parentResource, {
                data: {
                    mode: 'unlink',
                    recordId: parentId,
                    relationIds: [record.id],
                },
            })
            .then(refresh)
            .catch(error => {
                notify(error.message, 'warning')
                setLoading(false);
            });
    };

    if(!record) {
        return null;
    }

    return (
        <>
            <Tooltip title={translate('action.unlink')}>
                <IconButton
                    color={"primary"}
                    aria-label={translate('action.unlink')}
                    onClick={() => setDialogOpen(true)}
                    disabled={isLoading}

                >
                    <LinkOff/>

                </IconButton>
            </Tooltip>
            <Confirm
                isOpen={dialogOpen}
                loading={isLoading}
                title={translate('action.unlink')}
                content={translate('common.unlink_confirmation', {id: record.id})}
                onConfirm={handleUnlink}
                onClose={() => setDialogOpen(false)}/>
        </>
    );


};

export default UnlinkButton;
