import * as React from 'react';
import InfoNotice from './InfoNotice';

interface IProps {
  className?: string;
}

const LinkNotice = ({ className }: IProps) => {
  return (
    <InfoNotice
      text={
        <>
          Use <code>&mdi-extern=true</code> for external links, or{' '}
          <code>?mdi-extern=true</code> if there is no <code>?</code> in the link
        </>
      }
      className={className}
    />
  );
};

export default LinkNotice;
