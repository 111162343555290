import {
  BooleanInput,
  DateField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import React from 'react';
import { titleWithFlagRenderer } from '../common/optionRendererFactory';
import {
  AuthorizedToolbar,
  ImageField,
  ImageInput,
  RelatedEntityField,
  TranslationInput,
} from '../common/custom-components';
import { validFilename } from '../common/validators';
import { SimpleFormProps } from 'ra-ui-materialui/lib/form/SimpleForm';
import { useCheckAbilities } from '../common/custom-hooks';

interface Props extends SimpleFormProps, ResourceComponentProps {}

const CategoryForm = (props: Props) => {
  const [canUpdate] = useCheckAbilities(can => [can('update', 'Category')]);

  return (
    <SimpleForm {...props} toolbar={<AuthorizedToolbar subject={'Category'} />}>
      <BooleanInput disabled={!canUpdate} source={'isPublished'} />
      <ReferenceInput source="language" reference="language">
        <SelectInput disabled={!canUpdate} optionText="label" />
      </ReferenceInput>

      {props.record.translatedById ? (
        <RelatedEntityField
          reference={'category'}
          source={'translatedById'}
          optionTextRenderer={titleWithFlagRenderer}
        />
      ) : (
        <TranslationInput
          label={'common.go_to_translation'}
          source={'translationOfId'}
          disabled={!canUpdate}
          reference={'category'}
          optionTextRenderer={titleWithFlagRenderer}
        />
      )}

      <TextInput disabled={!canUpdate} source="title" />

      {canUpdate ? (
        <ImageInput validate={validFilename} source={'image'} multiple={false}>
          <ImageField source={'url'} filename={'filename'} />
        </ImageInput>
      ) : (
        <ImageField source={'image'} src={'url'} filename={'filename'} />
      )}

      {props.record.createdAt && <DateField source="createdAt" />}
      {props.record.createdBy && <TextField source="createdBy" />}
      {props.record.updatedAt && <DateField source="updatedAt" />}
      {props.record.updatedBy && <TextField source="updatedBy" />}
    </SimpleForm>
  );
};

export default CategoryForm;
