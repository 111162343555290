import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateField,
  FormDataConsumer,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import {
  AuthorizedToolbar,
  BelongsToSingle,
  ImageField,
  ImageInput,
  RelatedEntityField,
  TranslationInput,
} from '../common/custom-components';
import { validFilename } from '../common/validators';
import { useCheckAbilities } from '../common/custom-hooks';
import { titleWithFlagRenderer } from '../common/optionRendererFactory';
import { SimpleFormProps } from 'ra-ui-materialui/lib/form/SimpleForm';

interface Props extends SimpleFormProps, ResourceComponentProps {}

const SubcategoryForm = (props: Props) => {
  const [canUpdate] = useCheckAbilities(can => [can('update', 'Subcategory')]);
  return (
    <SimpleForm {...props} toolbar={<AuthorizedToolbar subject={'Subcategory'} />}>
      <BooleanInput disabled={!canUpdate} source={'isPublished'} />

      <ReferenceInput source="language" reference="language">
        <SelectInput disabled={!canUpdate} optionText="label" validate={required()} />
      </ReferenceInput>

      {props.record.translatedById ? (
        <RelatedEntityField
          reference={'subcategory'}
          source={'translatedById'}
          optionTextRenderer={titleWithFlagRenderer}
        />
      ) : (
        <TranslationInput
          label={'common.go_to_translation'}
          source={'translationOfId'}
          disabled={!canUpdate}
          reference={'subcategory'}
          optionTextRenderer={titleWithFlagRenderer}
        />
      )}

      <BelongsToSingle source={'categoryId'} reference={'category'} label={'resources.subcategory.go_to_category'} />

      <FormDataConsumer>
        {({ formData }) => (
          <ReferenceInput source={'categoryId'} reference={'category'} filter={{ lang: formData.language }}>
            <AutocompleteInput disabled={!canUpdate} optionText={titleWithFlagRenderer} />
          </ReferenceInput>
        )}
      </FormDataConsumer>

      <TextInput disabled={!canUpdate} source="title" validate={required()} />

      {canUpdate ? (
        <ImageInput validate={validFilename} source={'image'} multiple={false}>
          <ImageField source={'url'} filename={'filename'} />
        </ImageInput>
      ) : (
        <ImageField source={'image'} src={'url'} filename={'filename'} />
      )}

      {props.record.createdAt && <DateField source="createdAt" />}
      {props.record.createdBy && <TextField source="createdBy" />}
      {props.record.updatedAt && <DateField source="updatedAt" />}
      {props.record.updatedBy && <TextField source="updatedBy" />}
    </SimpleForm>
  );
};

export default SubcategoryForm;
