import React from 'react';
import {makeStyles} from "@material-ui/core";
import {RichTextField} from "react-admin";

const TruncatedTextField = props => {
    const classes = useStyles();
    return <RichTextField
        {...props}
        stripTags={true}
        className={classes.truncate}
    />;
};

const useStyles = makeStyles(theme => ({
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '15vw',
        display: 'block',
    }
}));

export default TruncatedTextField;
