import React from 'react';
import Edit from "../common/custom-components/Edit";
import CategoryForm from "./CategoryForm";
import RelatedSubcategoryList from "./RelatedSubcategoryList";

const CategoryEdit = props => {

    return (
        <Edit {...props} aside={<RelatedSubcategoryList/>}>
            <CategoryForm redirect={false}/>
        </Edit>
    );
};

export default CategoryEdit;
