import {Box, makeStyles} from "@material-ui/core";
import React from "react";

const RelatedEntitiesHeader = ({children}) => {
    const classes = useStyles();

    return (
        <Box className={classes.header}>
            {children}
        </Box>
    );
};

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default RelatedEntitiesHeader;
