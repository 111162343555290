import React from 'react';
import { SelectInput } from 'react-admin';
import TopicPurpose from '../../topicPurpose';

const TopicPurposeInput = (props: React.ComponentProps<typeof SelectInput>) => (
  <SelectInput
    {...props}
    choices={[
      { id: TopicPurpose.DEFAULT, name: `resources.topic.purpose.${TopicPurpose.DEFAULT}` },
      { id: TopicPurpose.INSPIRATION, name: `resources.topic.purpose.${TopicPurpose.INSPIRATION}` },
      { id: TopicPurpose.INSPIRATION_FALLBACK, name: `resources.topic.purpose.${TopicPurpose.INSPIRATION_FALLBACK}` },
      { id: TopicPurpose.NEWS, name: `resources.topic.purpose.${TopicPurpose.NEWS}` },
    ]}
  />
);

TopicPurposeInput.defaultProps = {
  allowEmpty: false,
};

export default TopicPurposeInput;
