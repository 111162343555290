import React from 'react';
import {Datagrid} from "react-admin";
import {makeStyles} from "@material-ui/core";

const DenseDatagrid = (props) => {

    const classes = useStyles();

    return <Datagrid classes={{
        headerCell: classes.cell,
        rowCell: classes.cell
    }} {...props} />
};

const useStyles = makeStyles({
    cell: {
        paddingRight: 6,
        paddingLeft: 6,
    },
});

export default DenseDatagrid;
