import React from 'react';
import { SaveButton, Title, useDataProvider, useNotify, useQuery, useTranslate } from 'react-admin';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import { RichTextInput } from '../common/custom-components';
import { Form } from 'react-final-form';
import { useCheckAbilities } from '../common/custom-hooks';

export default () => {
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [canUpdateAdminNote] = useCheckAbilities(can => [can('update', 'Note', 'adminNote')]);

  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  const { data } = useQuery({
    type: 'getOne',
    resource: 'note',
    payload: { id: 1 },
  });

  const onSubmit = (values: any) => {
    const note = values['note'];
    const adminNote = values['adminNote'];
    return dataProvider
      .update('note', { data: { note, adminNote }, id: 1, previousData: null })
      .then(() => notify('common.updated'))
      .catch(error => notify(error.message, 'warning'));
  };

  return (
    <Card>
      <Title title={translate('common.dashboard.title')} />
      <CardContent>
        {data && (
          <Form
            initialValues={data}
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form>
                <RichTextInput id="note" record={data} source={'note'} addColors={true} />
                {canUpdateAdminNote && (
                  <RichTextInput id="adminNote" record={data} source={'adminNote'} addColors={true} />
                )}
                <SaveButton
                  saving={submitting}
                  disabled={submitting || pristine}
                  handleSubmitWithRedirect={handleSubmit}
                />
              </form>
            )}
          />
        )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  '@global': {
    '.ra-rich-text-input .ql-container': {
      height: 600,
    },
  },
});
