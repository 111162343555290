import React from 'react';
import { Create } from 'react-admin';
import DailyForm from './DailyForm';

const defaultDaily = {
  language: 'de',
  isPublished: false,
  showTitleInTeaser: false,
  toBeCheckedAt: null,
  topicIds: [],
};

const DailyCreate = props => {
  return (
    <Create {...props}>
      <DailyForm initialValues={defaultDaily} />
    </Create>
  );
};

export default DailyCreate;
