import { AuthProvider as RaAuthProvider } from 'react-admin';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { getCurrentUser, httpClient } from './providerUtils';
import { unpackRules } from '@casl/ability/extra';
import { Ability, RawRuleOf } from '@casl/ability';
import { AppAbility, AppAbilities } from '../common/appAbilities';

const PERMISSIONS_KEY = 'permissions';

export default class AuthProvider implements RaAuthProvider {
  private readonly auth: firebase.auth.Auth;

  constructor() {
    this.auth = firebase.auth();
  }

  async login({ username, password }: { username: string; password: string }) {
    try {
      await this.auth.signInWithEmailAndPassword(username, password);
      await this.fetchRules();
    } catch (e) {
      await this.logout();
      throw e;
    }
  }

  async fetchRules() {
    const { body } = await httpClient('/admin/auth/rules');
    localStorage.setItem(PERMISSIONS_KEY, body);
  }

  logout() {
    localStorage.removeItem(PERMISSIONS_KEY);
    return firebase.auth().signOut();
  }

  checkError(error: any) {
    const status = error.status;
    if (status === 401 || status === 403) {
      return firebase
        .auth()
        .signOut()
        .then(() => Promise.reject());
    }
    return Promise.resolve();
  }

  async checkAuth() {
    const currentUser = await getCurrentUser();
    return currentUser ? Promise.resolve() : Promise.reject();
  }

  async getPermissions(): Promise<Ability<AppAbilities>> {
    const rules = localStorage.getItem(PERMISSIONS_KEY);
    return createAbility(unpackRules(JSON.parse(rules)));
  }
}

const createAbility = (rules: RawRuleOf<AppAbility>[]) => new Ability<AppAbilities>(rules);
