import { Ability, ForcedSubject, subject } from '@casl/ability';
import ContentStatus from './contentStatus';

const actions = [
  'manage',
  'view',
  'create',
  'update',
  'assignToParent',
  'addChild',
  'createTranslation',
  'delete',
  'export',
] as const;

const subjects = [
  'all',
  'Note',
  'Category',
  'Subcategory',
  'Topic',
  'Daily',
  'Statistics',
  'Admin',
  'Label',
] as const;

export type Subject = typeof subjects[number];

export type AppAbilities = [typeof actions[number], Subject | ForcedSubject<Exclude<Subject, 'all'>>];
export type AppAbility = Ability<AppAbilities>;

export function getAllowedContentStatus(
  ability: AppAbility,
  subjectType: Subject,
  data: any,
  fieldName = 'contentStatus'
): ContentStatus[] {
  return Object.values(ContentStatus).filter(
    contentStatus => ability.can('update', subject(subjectType, { ...data, [fieldName]: contentStatus }), fieldName)
  );
}
