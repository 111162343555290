import {
  CreateParams,
  DataProvider as RaDataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  Identifier,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import { stringify } from 'query-string';
import { DoLaterType } from '../common/DoLaterType';
import { httpClient } from './providerUtils';
import { formatDate } from '../common/custom-components/utils';

export interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
}

export default class DataProvider implements RaDataProvider {
  getList(resource: string, params: GetListParams) {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sort: order,
      page: page,
      limit: perPage,
      ...params.filter,
    };
    const url = `/admin/${resource}?${stringify(query, { arrayFormat: 'bracket' })}`;

    return httpClient(url).then(({ json }) => ({
      data: json.items,
      total: json.totalItems,
    }));
  }

  getOne(resource: string, params: GetOneParams) {
    return httpClient(`/admin/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    }));
  }

  getMany(resource: string, params: GetManyParams) {
    const query = {
      ids: params.ids,
    };
    const url = `/admin/${resource}/many?${stringify(query, { arrayFormat: 'bracket' })}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  }

  getManyReference(resource: string, params: GetManyReferenceParams) {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sort: order,
      page: page,
      limit: perPage,
      ...params.filter,
      [params.target]: params.id,
    };
    const url = `/admin/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.items,
      total: json.totalItems,
    }));
  }

  async update(resource: string, params: UpdateParams) {
    const data = await this.checkForNewImages(params.data);
    const { json } = await httpClient(`/admin/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
    return { data: json };
  }

  updateMany(resource: string, params: UpdateManyParams) {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`/admin/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  }

  async create(resource: string, params: CreateParams) {
    const data = await this.checkForNewImages(params.data);
    const { json } = await httpClient(`/admin/${resource}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return { data: json };
  }

  // ToDo: Problems with type signature
  // @ts-ignore
  async delete(resource: string, params: DeleteParams) {
    await httpClient(`/admin/${resource}/${params.id}`, {
      method: 'DELETE',
    });
    return { data: params.previousData };
  }

  async deleteMany(resource: string, params: DeleteManyParams) {
    const query = {
      ids: params.ids,
    };
    await httpClient(`/admin/${resource}?${stringify(query, { arrayFormat: 'bracket' })}`, {
      method: 'DELETE',
    });
    return { data: params.ids };
  }

  associate(
    resource: string,
    params: {
      data: {
        mode: 'link' | 'unlink';
        recordId: Identifier;
        relationIds: Identifier[];
      };
    }
  ): Promise<{ data: unknown }> {
    return httpClient(`/admin/${resource}/associate`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  }

  reorder(
    resource: string,
    params: {
      data: {
        parentId: Identifier;
        childId: Identifier;
        previousOrder: number;
        newOrder: number;
      };
    }
  ): Promise<{ data: unknown }> {
    return httpClient(`/admin/${resource}/reorder`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  }

  fetchNextUnassignedDate(
    resource: string,
    params: { topicId: Identifier; today: string }
  ): Promise<{ data: string | null }> {
    return httpClient(`/admin/${resource}/next-unassigned-date?${stringify(params)}`).then(({ body }) => ({
      data: body,
    }));
  }

  fetchSubscriptionDuration(resource: string, params: GetOneParams) {
    return httpClient(`/admin/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    }));
  }

  fetchQuizStatistics(resource: string, params: GetOneParams) {
    return httpClient(`/admin/${resource}/${params.id}/quiz-statistics`).then(({ json }) => ({
      data: json,
    }));
  }

  fetchRatingStatistics(resource: string, params: GetOneParams) {
    return httpClient(`/admin/${resource}/${params.id}/rating-statistics`).then(({ json }) => ({
      data: json,
    }));
  }

  fetchActiveUsers(resource: string, params: { requestDate: Date }) {
    return httpClient(`/admin/${resource}/active-users?date=${formatDate(params.requestDate)}`).then(({ json }) => ({
      data: json,
    }));
  }

  fetchCounts(resource: string, params: GetOneParams) {
    return httpClient(`/admin/${resource}/count`).then(({ json }) => ({
      data: json,
    }));
  }

  getNeighbor(
    resource: string,
    params: { parentId: Identifier; childId: Identifier, direction: 'next' | 'prev' }
  ): Promise<{ data: { id: Identifier | null } }> {
    return httpClient(`/admin/${resource}/neighbor?${stringify(params)}`).then(({ json }) => ({
      data: json,
    }));
  }

  fetchStatistics(resource: string, params: Record<string, string> = {}): Promise<{ data: any }> {
    return httpClient(`/admin/${resource}/stats?${stringify(params)}`).then(({ json }) => ({ data: json }));
  }

  async createTranslation(
    resource: string,
    params: { id: Identifier; withChildren?: boolean }
  ): Promise<{ data: Identifier }> {
    const query = {
      withChildren: params.withChildren || false,
    };

    const { body } = await httpClient(`/admin/${resource}/${params.id}/translate?${stringify(query)}`, {
      method: 'POST',
    });
    return { data: body };
  }

  async quizify(
    resource: string,
    params: { id: Identifier; withChildren?: boolean }
  ): Promise<{ data: Identifier }> {
    const query = {
      withChildren: params.withChildren || false,
    };

    const { body } = await httpClient(`/admin/${resource}/${params.id}/quizify?${stringify(query)}`, {
      method: 'POST',
    });
    return { data: body };
  }

  async checkForNewImages(data: any): Promise<any> {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (Array.isArray(value) && value.some(item => item.rawFile instanceof File)) {
          const files = value.filter(p => p.rawFile instanceof File).map(image => image.rawFile);

          const formerImages = value.filter(p => !(p.rawFile instanceof File));

          if (files.length > 0) {
            const newImages = await this.uploadImages(files);
            data[key] = [...formerImages, ...newImages];
          }
        } else if (value && value.hasOwnProperty('rawFile') && value.rawFile instanceof File) {
          const newImages = await this.uploadImages([value.rawFile]);
          data[key] = newImages[0];
        }
      }
    }

    return data;
  }

  async uploadImages(files: File[]): Promise<DoLaterType> {
    const data = new FormData();
    for (const file of files) {
      data.append('files', file);
    }

    const { json } = await httpClient(`/admin/image`, {
      headers: new Headers({}),
      method: 'POST',
      body: data,
    });

    return json;
  }
}
