import React from 'react';
import { ImageInput as ReactAdminImageInput } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';

const ImageInput = (props: React.ComponentProps<typeof ReactAdminImageInput>) => {
  const classes = useStyles(props);
  return <ReactAdminImageInput classes={classes} {...props} />;
};

// Overriding classes of ImageInput to customize z-index of removeButton
// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/input/ImageInput.tsx
const useStyles = makeStyles(
  theme => ({
    root: { width: '100%' },
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
    preview: {
      display: 'inline-block',
    },
    removeButton: {
      display: 'inline-block',
      position: 'relative',
      float: 'left',
      '& button': {
        zIndex: 1, // <---- this is added
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        minWidth: theme.spacing(2),
        opacity: 0,
      },
      '&:hover button': {
        opacity: 1,
      },
    },
  }),
  { name: 'RaImageInput' }
);

export default ImageInput;
