import {createMuiTheme} from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        primary: {main: '#00A2FF'},
        secondary: {main: '#0123FF'},
    },
    typography: {
        fontSize: 14,
    },
    overrides: {
        MuiTableCell: {
            sizeSmall: {
                padding: '6px 16px 6px 12px'
            }
        }
    },
});
