import { Filter, FilterProps, NullableBooleanInput, SearchInput, useTranslate } from 'react-admin';
import React from 'react';
import { BelongsToFilter, ContentStatusFilter, LabelInput, TopicTypeInput } from '../common/custom-components';

const DailyFilter = (props: Omit<FilterProps, 'children'>) => {
  const t = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput placeholder={t('action.search_title')} source="q" alwaysOn />
      <LabelInput source="labelIds" optionText={'abbreviation'} alwaysOn />
      <LabelInput source="withoutLabelIds" optionText={'abbreviation'} />
      <TopicTypeInput source={'type'} />
      <ContentStatusFilter source={'contentStatus'} />
      <SearchInput placeholder={t('action.search_fulltext')} source="fullText" />
      <SearchInput placeholder={t('action.search_fulltext_with_note')} source="fullTextWithNote" />
      <SearchInput placeholder={t('action.search_fulltext_only_note')} source="fullTextOnlyNote" />
      <NullableBooleanInput source={'hasTranslation'} />
      <NullableBooleanInput source={'hasQuiz'} />
      <NullableBooleanInput source={'isPublished'} />
      <BelongsToFilter source={'topicIds'} reference={'topic'} />
    </Filter>
  );
};

export default DailyFilter;
