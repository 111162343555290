import { FormWithRedirect, FormWithRedirectProps, Toolbar, useTranslate } from 'react-admin';
import React from 'react';
import { Accordion, AuthorizedToolbar, LinkNotice, QuizifyButton, TranslateButton } from '../common/custom-components';
import { Settings, Subject, SupervisorAccount, Translate } from '@material-ui/icons';
import ContentSection from './formSections/ContentSection';
import LanguageSection from './formSections/LanguageSection';
import GeneralSection from './formSections/GeneralSection';
import AdminSection from './formSections/AdminSection';
import { useCheckAbilities } from '../common/custom-hooks';
import { subject } from '@casl/ability';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = Omit<FormWithRedirectProps, 'render'> & { initialValues: any };

const TopicForm = (props: Props) => {
  const record = {
    ...(props.initialValues ?? {}),
    ...(props.record ?? {}),
  };

  return (
    <FormWithRedirect
      key={`topic-${record?.id}`}
      {...props}
      record={record}
      render={formProps => <FormView {...formProps} />}
    />
  );
};

type FormViewProps = React.ComponentProps<typeof Toolbar>;

const FormView = ({
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  mutationMode,
  ...rest
}: FormViewProps) => {
  const [canTranslate] = useCheckAbilities(can => [can('createTranslation', subject('Topic', record))]);
  const t = useTranslate();
  const classes = useStyles();

  const fieldProps = {
    basePath,
    record,
    resource,
  };

  return (
    <form className={'simple-form'}>
      <Accordion title={t('common.form_sections.general')} icon={<Settings />}>
        <GeneralSection {...fieldProps} />
      </Accordion>

      <Accordion title={t('common.form_sections.language')} icon={<Translate />}>
        <LanguageSection {...fieldProps} />
      </Accordion>

      <Accordion title={t('common.form_sections.content')} icon={<Subject />}>
        <ContentSection {...fieldProps} />
      </Accordion>

      <Accordion title={t('common.form_sections.admin')} icon={<SupervisorAccount />}>
        <AdminSection {...fieldProps} />
      </Accordion>

      <AuthorizedToolbar
        subject={'Topic'}
        basePath={basePath}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        handleSubmit={handleSubmit}
        invalid={invalid}
        pristine={pristine}
        record={record}
        redirect={redirect}
        resource={resource}
        saving={saving}
        submitOnEnter={submitOnEnter}
        undoable={undoable}
        mutationMode={mutationMode}
        renderRight={toolbarProps => (
          <>
            {canTranslate &&
              toolbarProps.record.translationOfId === null &&
              toolbarProps.record.translatedById === null && (
                <TranslateButton {...toolbarProps} allowChildCopy={true} />
              )}
            {canTranslate && toolbarProps.record.quizForId === null && toolbarProps.record.quizId === null && (
              <QuizifyButton {...toolbarProps} allowChildCopy={true} />
            )}
          </>
        )}
      />
      <LinkNotice className={classes.linkNotice} />
    </form>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    linkNotice: {
      margin: theme.spacing(1),
    },
  })
);

export default TopicForm;
