import React from 'react';
import { FieldProps, useTranslate } from 'react-admin';
import ContentStatus from '../../contentStatus';
import { Tooltip, Typography } from '@material-ui/core';
import get from 'lodash/get';

const statusDisplay = {
  [ContentStatus.NONE]: '-',
  [ContentStatus.CHANGE_REQUIRED]: '1',
  [ContentStatus.WORK_IN_PROGRESS]: '2',
  [ContentStatus.READY_TO_REVIEW]: '3',
  [ContentStatus.READY_TO_PUBLISH]: '4',
};

const ContentStatusField = (props: FieldProps) => {
  const { record, source } = props;
  const t = useTranslate();
  const value: any = get(record, source);

  return value ? (
    <Typography component="span" variant="body2">
      <Tooltip title={t(`common.contentStatus.${value}`)}>
        <span>{statusDisplay.hasOwnProperty(value) ? statusDisplay[value as ContentStatus] : '-'}</span>
      </Tooltip>
    </Typography>
  ) : (
    <Typography component="span" variant="body2">
      -
    </Typography>
  );
};

export default ContentStatusField;
