import React from 'react';
import { AdminContext } from 'react-admin';
import { translationProvider } from './translations/translationProvider';
import { customReducers } from './redux';
import { AuthProvider, cacheDataProvider, DataProvider, initFirebase } from './provider';
import Resources from './Resources';

initFirebase();
const dataProvider = cacheDataProvider(new DataProvider());
const authProvider = new AuthProvider();

function App() {
  return (
    <AdminContext
      customReducers={customReducers}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={translationProvider}
    >
      <Resources authProvider={authProvider} />
    </AdminContext>
  );
}

export default App;
