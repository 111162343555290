import React from 'react';
import {Create,} from 'react-admin';
import CategoryForm from "./CategoryForm";


const defaultCategory = {
    language: 'de',
    isPublished: false,
};

const CategoryCreate = props => (
    <Create {...props}>
        <CategoryForm initialValues={defaultCategory}/>
    </Create>
);

export default CategoryCreate;
