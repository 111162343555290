import Aside from '../common/custom-components/Aside';
import { Button, EditButton, Record, ReferenceManyField, TextField, useTranslate } from 'react-admin';
import { CustomPagination, DenseDatagrid, RelatedEntitiesHeader, Spacer } from '../common/custom-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { useCheckAbilities } from '../common/custom-hooks';

interface Props {
  basePath?: string;
  record?: Record;
  resource?: string;
}

const RelatedSubcategoryList = ({ basePath, record, resource, ...other }: Props) => {
  const [canCreateSubcategory] = useCheckAbilities(can => [can('create', 'Subcategory')]);
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Aside title={translate('resources.subcategory.name', 10)}>
      {record && (
        <>
          <RelatedEntitiesHeader>
            <Spacer />
            {canCreateSubcategory && (
              <Button
                component={Link}
                to={{
                  pathname: '/subcategory/create',
                  state: { record: { categoryId: record.id } },
                }}
                label={'resources.subcategory.create'}
              >
                <Add />
              </Button>
            )}
          </RelatedEntitiesHeader>
          <ReferenceManyField
            basePath={basePath}
            resource={resource}
            record={record}
            pagination={<CustomPagination className={classes.pagination} />}
            addLabel={false}
            reference="subcategory"
            target="categoryId"
          >
            <DenseDatagrid label={'Subkategorien'}>
              <TextField source="id" />
              <TextField source="title" />
              <EditButton />
            </DenseDatagrid>
          </ReferenceManyField>
        </>
      )}
    </Aside>
  );
};

const useStyles = makeStyles({
  pagination: {
    float: 'right',
  },
});

export default RelatedSubcategoryList;
