import React from 'react';
import EditableTextField from './EditableTextField';
import { FieldProps, Identifier, Record, useDataProvider, useNotify, useRefresh } from 'react-admin';

interface Props {
  parentResource: string;
  parentId: Identifier;
  value: (record: Record) => number;
}

const ReorderField = ({ parentResource, parentId, record, value, className }: Props & FieldProps) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onChange = async (previous: string, current: string) => {
    try {
      const previousOrder = parseInt(previous, 10);
      const newOrder = parseInt(current, 10);

      await dataProvider.reorder(parentResource, {
        data: {
          parentId,
          childId: record.id,
          previousOrder,
          newOrder,
        },
      });
      refresh();
    } catch (e) {
      notify(e.message, 'warning');
    }
  };

  try {
    const initialValue = value(record);
    return <EditableTextField className={className} initialValue={`${initialValue}`} onChange={onChange} />;
  } catch (e) {
    return <span>Error</span>;
  }
};

export default ReorderField;
