import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { ChipFieldProps, Record, sanitizeFieldRestProps, useRecordContext } from 'react-admin';

interface Props extends ChipFieldProps {
  render: (record: Record, source: string) => React.ReactNode;
}

const useStyles = makeStyles(
  {
    chip: { margin: 4, cursor: 'inherit' },
  },
  { name: 'RaFunctionChipField' }
);

export const FunctionChipField = (props: Props) => {
  const { className, source, render, ...rest } = props;

  const classes = useStyles();
  const record = useRecordContext(props);
  return (
    <Chip
      className={classnames(classes.chip, className)}
      label={render(record, source)}
      size={'small'}
      {...sanitizeFieldRestProps(rest)}
    />
  );
};

FunctionChipField.defaultProps = {
  addLabel: true,
};

FunctionChipField.displayName = 'EnhancedFunctionChipField';

export default FunctionChipField;
