import * as React from 'react';
import { Box } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  Record,
  ReferenceArrayField,
  ReferenceArrayInput,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { ContentStatusInput, LabelInput, RichTextInput, WrapInput } from '../../common/custom-components';
import { useAbilities } from '../../common/custom-hooks';
import { getAllowedContentStatus } from '../../common/appAbilities';
import { subject } from '@casl/ability';

interface Props {
  basePath: string;
  record: Record;
  resource: string;
}

const AdminSection = (props: Props) => {
  const { ...fieldProps } = props;
  const abilites = useAbilities();
  const allowedContentStatus = getAllowedContentStatus(abilites, 'Topic', props.record);

  const canViewAssignedAdmins = abilites.can('view', subject('Topic', props.record), 'assignedAdminIds');
  const canUpdateAssignedAdmins = abilites.can('update', subject('Topic', props.record), 'assignedAdminIds');
  const canUpdateAdminNote = abilites.can('update', subject('Topic', props.record), 'adminNote');

  return (
    <Box>
      <WrapInput {...fieldProps}>
        <RichTextInput countChars={false} addColors={true} source={'note'} />
      </WrapInput>
      {canUpdateAdminNote && (
        <WrapInput {...fieldProps}>
          <RichTextInput countChars={false} addColors={true} source={'adminNote'} />
        </WrapInput>
      )}
      <WrapInput {...fieldProps}>
        <ContentStatusInput
          source={'contentStatus'}
          disabled={allowedContentStatus.length === 0}
          allowedContentStatus={allowedContentStatus}
        />
      </WrapInput>
      <WrapInput {...fieldProps}>
        <LabelInput source="labelIds" />
      </WrapInput>

      {canUpdateAssignedAdmins && (
        <WrapInput {...fieldProps}>
          <ReferenceArrayInput source={'assignedAdminIds'} reference={'admin'}>
            <AutocompleteArrayInput optionText={'email'} />
          </ReferenceArrayInput>
        </WrapInput>
      )}
      {canViewAssignedAdmins && !canUpdateAssignedAdmins && (
        <WrapInput {...fieldProps}>
          <ReferenceArrayField source={'assignedAdminIds'} reference={'admin'}>
            <SingleFieldList linkType={false}>
              <TextField source={'email'} />
            </SingleFieldList>
          </ReferenceArrayField>
        </WrapInput>
      )}
    </Box>
  );
};

export default AdminSection;
