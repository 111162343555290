import { makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

interface Props {
  title: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

const Aside = ({title, className, children}: Props) => {

    const classes = useStyles();

    return (
        <Paper className={classNames(classes.container, className)}>
            <Typography variant={"h6"}>{title}</Typography>
            {children}
        </Paper>
    );
};

const useStyles = makeStyles({
    container: {
        flexBasis: '40em',
        marginLeft: '1em',
        padding: '1em',
        paddingBottom: 80,
    },
});

export default Aside;
