import React from "react";
import {Filter, SearchInput, useTranslate} from "react-admin";
import {BelongsToFilter} from "../common/custom-components";


const SubcategoryFilter = (props) => {

    const t = useTranslate();

    return (
        <Filter {...props}>
            <SearchInput placeholder={t('action.search_title')} source="q" alwaysOn/>
            <BelongsToFilter source={"categoryIds"} reference={"category"}/>
        </Filter>
    );
};

export default SubcategoryFilter;
