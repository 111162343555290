import * as React from 'react';
import { useTranslate } from 'react-admin';
import CopyButton, { CopyButtonProps } from './CopyButton';

type Props = Omit<CopyButtonProps, 'mutationType' | 'actionLabel'>;

const QuizifyButton = (props: Props) => {
  const t = useTranslate();

  return <CopyButton {...props} mutationType={'quizify'} actionLabel={t('common.copy_quizify')} />;
};

export default QuizifyButton;
