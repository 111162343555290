import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React from 'react';
import { Loading, useQuery } from 'react-admin';
import { useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const UsersActiveChart = () => {
  const theme = useTheme<Theme>();

  const requestDate = React.useRef(new Date());

  const { data, loading, error } = useQuery(
    {
      type: 'fetchActiveUsers',
      resource: 'user',
      payload: { requestDate: requestDate.current },
    },
    {}
  );

  if (loading) return <Loading />;

  if (error) {
    return <Typography color={'error'}>{error.message}</Typography>;
  }

  const styleProps = {
    fontFamily: 'Roboto, sans-serif',
  };
  const labelStyle = {
    ...styleProps,
    fontSize: 12,
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <XAxis dataKey="daysOnline" interval={0} tick={{ ...styleProps, fontSize: 12 }} />
        <YAxis name="Users" {...styleProps} padding={{ top: 20 }} />
        <CartesianGrid strokeDasharray="3 3" />
        <Legend wrapperStyle={styleProps} />
        <Tooltip
          labelFormatter={value => `${value} Days in App`}
          formatter={label => `${label} Users`}
          contentStyle={styleProps}
        />
        <Bar stackId={'stack'} dataKey="de" fill={theme.palette.primary.main} label={{ style: labelStyle }} />
        <Bar
          stackId={'stack'}
          dataKey="en"
          fill={theme.palette.secondary.main}
          label={{ position: 'top', style: labelStyle }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default UsersActiveChart;
