import React, { ComponentProps } from 'react';
import RelatedEntityInput from './RelatedEntityInput';
import { useFormState } from 'react-final-form';

const TranslationInput = (props: ComponentProps<typeof RelatedEntityInput>) => {
  const { values } = useFormState();
  return <RelatedEntityInput filter={{ notLang: values.language }} {...props} />;
};

TranslationInput.defaultProps = {
  addLabel: false,
};

export default TranslationInput;
