import { FormDataConsumer, FormWithRedirect, FormWithRedirectProps, Toolbar, useTranslate } from 'react-admin';
import React from 'react';
import { createStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Accordion, LinkNotice } from '../common/custom-components';
import SaveDailyToolbar from './SaveDailyToolbar';
import { Settings, Subject, SupervisorAccount, Translate } from '@material-ui/icons';
import { hasTeaserOrImage } from './validators';
import { makeStyles } from '@material-ui/core/styles';
import GeneralSection from './formSections/GeneralSection';
import LanguageSection from './formSections/LanguageSection';
import ContentSection from './formSections/ContentSection';
import AdminSection from './formSections/AdminSection';

type Props = Omit<FormWithRedirectProps, 'render'> & { initialValues: any };

const DailyForm = (props: Props) => {
  const record = {
    ...(props.initialValues ?? {}),
    ...(props.record ?? {}),
  };

  return (
    <FormWithRedirect
      key={`daily-${record?.id}`}
      {...props}
      validate={hasTeaserOrImage}
      record={record as any}
      render={formProps => <FormView {...formProps} />}
    />
  );
};

type FormViewProps = React.ComponentProps<typeof Toolbar>;

const FormView = ({
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}: FormViewProps) => {
  // eslint-disable-next-line
  const classes = useStyles();
  const t = useTranslate();

  const { search } = useLocation();
  const param = new URLSearchParams(search ?? '').get('cameFromTopic');
  const topicId = typeof param === 'string' ? parseInt(param, 10) : undefined;
  const cameFromTopic = React.useRef(topicId);

  const fieldProps = {
    basePath,
    record,
    resource,
  };

  // @ts-ignore
  return (
    <form className={'simple-form'}>
      <Accordion title={t('common.form_sections.general')} icon={<Settings />}>
        <GeneralSection {...fieldProps} />
      </Accordion>

      <Accordion title={t('common.form_sections.language')} icon={<Translate />}>
        <LanguageSection {...fieldProps} />
      </Accordion>

      <Accordion title={t('common.form_sections.content')} icon={<Subject />}>
        <ContentSection {...fieldProps} />
      </Accordion>

      <Accordion title={t('common.form_sections.admin')} icon={<SupervisorAccount />}>
        <AdminSection {...fieldProps} />
      </Accordion>

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <SaveDailyToolbar
            basePath={basePath}
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            handleSubmit={handleSubmit}
            invalid={invalid}
            pristine={pristine}
            record={record}
            redirect={redirect}
            resource={resource}
            saving={saving}
            submitOnEnter={submitOnEnter}
            undoable={undoable}
            withConfirmation={record.isPublished && formData.isPublished}
            topicId={cameFromTopic.current}
          />
        )}
      </FormDataConsumer>
      <LinkNotice className={classes.linkNotice} />
    </form>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      '[class$=".teaser"] .ql-container': {
        height: 150,
        maxWidth: 475,
      },
      '[class$=".text"] .ql-container': {
        height: 300,
        maxWidth: 475,
      },
      '[class$=".note"]': {
        marginTop: 16,
        paddingTop: 16,
        borderTop: '1px dashed grey',
      },
    },
    linkNotice: {
      margin: theme.spacing(1),
    },
  })
);

export default DailyForm;
