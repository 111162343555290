import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Button, ButtonProps, Loading, Record, useMutation } from 'react-admin';
import { useTranslate } from 'ra-core';
import { formatDecimal } from '../common/formatter';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ThumbsUpDownOutlined } from '@material-ui/icons';
import { InfoNotice } from '../common/custom-components';

interface Props extends ButtonProps {
  record?: Record;
}

const RatingStatisticsButton = ({ record, ...rest }: Props) => {
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStyles();
  const t = useTranslate();

  const [loadData, { data, loading, error }] = useMutation();

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    loadData({
      type: 'fetchRatingStatistics',
      resource: 'topic',
      payload: { id: record.id },
    });
    setOpen(true);
  };

  return (
    <>
      <Button disabled={!record?.id} label={'action.rating_statistics'} onClick={onClick} {...(rest as any)}>
        <ThumbsUpDownOutlined />
      </Button>

      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        {data && <DialogTitle>{record.title}</DialogTitle>}
        <DialogContent>
          {loading && <Loading />}
          {error && <Typography color={'error'}>{error.message}</Typography>}
          {data && (
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('resources.subscription.rating_statistics.daily_id')}</TableCell>
                  <TableCell>{t('resources.subscription.rating_statistics.daily_title')}</TableCell>
                  <TableCell align={'right'}>{t('resources.subscription.rating_statistics.negative')}</TableCell>
                  <TableCell align={'right'}>{t('resources.subscription.rating_statistics.neutral')}</TableCell>
                  <TableCell align={'right'}>{t('resources.subscription.rating_statistics.positive')}</TableCell>
                  <TableCell align={'right'}>{t('resources.subscription.rating_statistics.average_rating')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.bold}>{t('resources.subscription.rating_statistics.total')}</TableCell>
                  <TableCell className={classes.bold} align={'right'}>
                    {data.negativeRatings}
                  </TableCell>
                  <TableCell className={classes.bold} align={'right'}>
                    {data.neutralRatings}
                  </TableCell>
                  <TableCell className={classes.bold} align={'right'}>
                    {data.positiveRatings}
                  </TableCell>
                  <TableCell className={classes.bold} align={'right'}>
                    {formatDecimal(data.averageRating)}
                  </TableCell>
                </TableRow>
                {data.dailyRatingStatistics.map((row: any) => (
                  <TableRow key={row.dailyId}>
                    <TableCell>{row.dailyId}</TableCell>
                    <TableCell>{row.dailyTitle}</TableCell>
                    <TableCell align={'right'}>{row.negativeRatings}</TableCell>
                    <TableCell align={'right'}>{row.neutralRatings}</TableCell>
                    <TableCell align={'right'}>{row.positiveRatings}</TableCell>
                    <TableCell align={'right'}>{formatDecimal(row.averageRating)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <InfoNotice text={t('resources.subscription.rating_statistics.notice')} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

export default RatingStatisticsButton;
