import React from 'react';
import {
  Button,
  ButtonProps,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import TopicType from '../topicType';
import { onlyUpdateForKeys } from 'recompose';

type Props = { basePath?: string; label?: string } & ButtonProps;

const CreateDailyDialButton = ({
  basePath = '',
  className,
  classes: classesOverride,
  label = 'ra.action.create',
  ...rest
}: Props) => {
  const classes = useStyles({ classes: classesOverride });
  const translate = useTranslate();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Button color={'primary'} size={'small'} className={className} ref={anchorRef} onClick={handleToggle} {...rest}>
        <Add className={classes.leftIcon} />
        {translate(label)}
      </Button>

      <Popper style={{ zIndex: 10 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    key={TopicType.INDEX_BASED}
                    component={Link}
                    to={{
                      pathname: `${basePath}/create`,
                      state: { record: { type: TopicType.INDEX_BASED } },
                    }}
                  >
                    {translate(`resources.daily.${TopicType.INDEX_BASED}`)}
                  </MenuItem>
                  <MenuItem
                    key={TopicType.DATE_BASED}
                    component={Link}
                    to={{
                      pathname: `${basePath}/create`,
                      state: { record: { type: TopicType.DATE_BASED } },
                    }}
                  >
                    {translate(`resources.daily.${TopicType.DATE_BASED}`)}
                  </MenuItem>
                  <MenuItem
                    key={TopicType.QUIZ}
                    component={Link}
                    to={{
                      pathname: `${basePath}/create`,
                      state: { record: { type: TopicType.QUIZ, quizChoices: [{}, {}, {}, {}] } },
                    }}
                  >
                    {translate(`resources.daily.${TopicType.QUIZ}`)}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles(
  theme => ({
    leftIcon: {
      marginRight: theme.spacing(1),
      fontSize: 20,
    },
  }),
  { name: 'RaCreateButton' }
);

const enhance = onlyUpdateForKeys(['basePath', 'label', 'translate']);
export default enhance(CreateDailyDialButton);
