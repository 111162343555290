import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from 'react-redux';
import {languageActions, languageSelectors} from "../redux/language";

const LanguageSelector = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(languageSelectors.selectLanguage);

    const onChange = (event, newLanguage) => {
        dispatch(languageActions.changeLanguage(newLanguage));
    };
    return (

        <ToggleButtonGroup exclusive value={selectedLanguage} onChange={onChange} aria-label="languages">
          <ToggleButton className={classes.button} classes={{selected: classes.selected}} value="de"><span role={'img'} aria-label={'German'}>🇩🇪</span></ToggleButton>
          <ToggleButton className={classes.button} classes={{selected: classes.selected}} value="en"><span role={'img'} aria-label={'English'}>🇱🇷</span></ToggleButton>
            <ToggleButton className={classes.button} classes={{selected: classes.selected}} value="all">All</ToggleButton>
        </ToggleButtonGroup>
    );
}

const useStyles = makeStyles(theme => ({
        button: {
            color: 'rgba(255,255,255,0.8) !important',
        },
        selected: {
            color: 'rgba(255,255,255,1) !important',
        }
    }),
    {name: 'LanguageSelector'}
);

export default LanguageSelector;
