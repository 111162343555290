import * as React from 'react';
import { Box } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateField,
  FormDataConsumer,
  Record,
  ReferenceArrayInput,
  TextField,
} from 'react-admin';
import {
  BelongsToMany,
  RelatedEntityField,
  RelatedEntityInput,
  TopicPurposeInput,
  TopicTypeInput,
  WrapInput,
} from '../../common/custom-components';
import { useCheckAbilities } from '../../common/custom-hooks';
import { subject } from '@casl/ability';
import { titleRenderer, titleWithFlagRenderer } from '../../common/optionRendererFactory';
import TopicType from '../../common/topicType';
import { useFormState } from 'react-final-form';

interface Props {
  basePath: string;
  record: Record;
  resource: string;
}

const GeneralSection = (props: Props) => {
  const { ...fieldProps } = props;
  const { values } = useFormState();

  const [canPublish, canUpdatePurpose, canAssignToParent, canUpdateRelatedTopics] = useCheckAbilities(can => [
    can('update', subject('Topic', fieldProps.record), 'isPublished'),
    can('update', subject('Topic', fieldProps.record), 'purpose'),
    can('assignToParent', subject('Topic', fieldProps.record)),
    can('update', subject('Topic', fieldProps.record), 'relatedTopicIds'),
  ]);

  const isQuiz = props.record.type === TopicType.QUIZ;

  return (
    <Box flexGrow={1}>
      <Box display={'flex'}>
        <WrapInput {...fieldProps} mr={1} flexGrow={1}>
          <BooleanInput disabled={!canPublish} source="isPublished" />
        </WrapInput>

        <WrapInput formInputClassName={null} {...fieldProps}>
          {fieldProps.record.createdAt && <DateField source="createdAt" />}
        </WrapInput>
        <WrapInput formInputClassName={null} ml={1} {...fieldProps}>
          {fieldProps.record.createdBy && <TextField source="createdBy" />}
        </WrapInput>
        <WrapInput formInputClassName={null} ml={1} {...fieldProps}>
          {fieldProps.record.updatedAt && <DateField source="updatedAt" />}
        </WrapInput>
        <WrapInput formInputClassName={null} ml={1} {...fieldProps}>
          {fieldProps.record.updatedBy && <TextField source="updatedBy" />}
        </WrapInput>
      </Box>

      <Box display={'flex'}>
        <WrapInput {...fieldProps} mr={1}>
          <TopicTypeInput source={'type'} disabled={true} />
        </WrapInput>
        {!isQuiz && (
          <WrapInput {...fieldProps}>
            <TopicPurposeInput disabled={!canUpdatePurpose} source={'purpose'} />
          </WrapInput>
        )}
      </Box>

      {canAssignToParent && (
        <FormDataConsumer>
          {({ formData }) => (
            <WrapInput {...fieldProps} xs={12}>
              <ReferenceArrayInput
                source={'subcategoryIds'}
                reference={'subcategory'}
                filter={{ lang: formData.language }}
              >
                <AutocompleteArrayInput optionText={titleWithFlagRenderer} />
              </ReferenceArrayInput>
            </WrapInput>
          )}
        </FormDataConsumer>
      )}

      <WrapInput {...fieldProps} xs={12}>
        <BelongsToMany
          addLabel={!canAssignToParent}
          source={'subcategoryIds'}
          reference={'subcategory'}
          optionRenderer={titleWithFlagRenderer}
        />
      </WrapInput>

      {canUpdateRelatedTopics && (
        <FormDataConsumer>
          {({ formData }) => (
            <WrapInput {...fieldProps} xs={12}>
              <ReferenceArrayInput
                source={'relatedTopicIds'}
                reference={'topic'}
                filter={{ lang: formData.language, excludeId: fieldProps.record.id }}
              >
                <AutocompleteArrayInput optionText={titleWithFlagRenderer} />
              </ReferenceArrayInput>
            </WrapInput>
          )}
        </FormDataConsumer>
      )}

      <WrapInput {...fieldProps} xs={12}>
        <BelongsToMany
          addLabel={!canUpdateRelatedTopics}
          source={'relatedTopicIds'}
          reference={'topic'}
          optionRenderer={titleWithFlagRenderer}
        />
      </WrapInput>

      {props.record.quizId && (
        <WrapInput {...fieldProps} mt={2}>
          <RelatedEntityField reference={'topic'} source={'quizId'} optionTextRenderer={titleRenderer} />
        </WrapInput>
      )}
      {props.record.type === TopicType.QUIZ && (
        <WrapInput {...fieldProps} mt={2}>
          <RelatedEntityInput
            filter={{
              lang: values.language,
              type: TopicType.INDEX_BASED,
            }}
            optionTextRenderer={titleRenderer}
            reference={'topic'}
            source={'quizForId'}
            label={'resources.topic.quiz_source_topic'}
          />
        </WrapInput>
      )}
    </Box>
  );
};

export default GeneralSection;
