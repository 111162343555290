import React from 'react';
import { Box, createStyles } from '@material-ui/core';
import { FormInput, Record } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  children?: React.ReactElement;
  basePath: string;
  record: Record;
  resource: string;
  formInputClassName?: string | null;

  [styledProp: string]: any;
}

const WrapInput = ({ children, basePath, record, resource, formInputClassName, ...boxProps }: Props) => {
  const classes = useStyles();
  return (
    <Box {...boxProps}>
      {children && (
        <FormInput
          basePath={basePath}
          input={children}
          record={record}
          resource={resource}
          margin={'dense'}
          className={formInputClassName === undefined ? classes.formInput : formInputClassName}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      width: 256,
    },
  })
);

export default WrapInput;
