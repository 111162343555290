import React from 'react';
import { useTranslate, Record } from 'react-admin';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';

interface Props {
  record?: Record;
  source: string;
}

const CopyrightField = ({ record, source }: Props) => {
  const t = useTranslate();
  const value = get(record, source);

  return value ? (
    <Typography component="span" variant="body2">
      <span>{t(`common.copyright.${value}`)}</span>
    </Typography>
  ) : (
    <Typography component="span" variant="body2">
      -
    </Typography>
  );
};

export default CopyrightField;
