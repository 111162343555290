import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import ContentStatus from '../../contentStatus';

type Props = { allowedContentStatus?: ContentStatus[] } & React.ComponentProps<typeof SelectInput>;

const ContentStatusInput = ({ allowedContentStatus = [], ...props }: Props) => {
  const t = useTranslate();
  const allowEmpty = props.allowEmpty ?? false;

  const choices = Object.values(ContentStatus).map(status => ({
    id: status,
    name: t(`common.contentStatus.${status}`),
    disabled: !allowedContentStatus.find(allowedStatus => status === allowedStatus)
  }));

  return (
    <SelectInput
      {...props}
      initialValue={allowEmpty ? undefined : ContentStatus.NONE}
      allowEmpty={allowEmpty}
      choices={choices}
    />
  );
};

export default ContentStatusInput;
