import React from 'react';
import { BooleanField, Datagrid, DateField, FunctionField, ReferenceField, TextField } from 'react-admin';
import { IsTranslatedField, LanguageField, List } from '../common/custom-components';
import { titleRenderer } from '../common/optionRendererFactory';
import SubcategoryFilter from './SubcategoryFilter';

const SubcategoryList = props => (
  <List {...props} subject={'Subcategory'} applyGlobalLanguage={true} filters={<SubcategoryFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source={'language'} reference={'language'}>
        <LanguageField />
      </ReferenceField>
      <TextField source={'title'} />
      <ReferenceField sortable={false} source="categoryId" reference="category">
        <FunctionField source={'title'} render={titleRenderer} />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <IsTranslatedField />
      <BooleanField source={'isPublished'} />
    </Datagrid>
  </List>
);

export default SubcategoryList;
