import { FieldProps, useTranslate } from 'react-admin';
import { makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { addWeeks } from 'date-fns';
import get from 'lodash/get';
import ToBeCheckedAt from '../../toBeCheckedAt';
import classNames from 'classnames';

const ToBeCheckedAtField = (props: FieldProps) => {
  const { record, source } = props;
  const t = useTranslate();
  const classes = useStyles();
  const value = get(record, source);

  let toBeCheckedAt = undefined;

  if (value) {
    const date = new Date(value);
    if (date < addWeeks(new Date(), 1)) {
      toBeCheckedAt = ToBeCheckedAt.VERY_SOON;
    } else if (date < addWeeks(new Date(), 4)) {
      toBeCheckedAt = ToBeCheckedAt.SOON;
    } else {
      toBeCheckedAt = ToBeCheckedAt.FUTURE;
    }
  }

  return toBeCheckedAt ? (
    <Tooltip title={t(`common.toBeCheckedAt.${toBeCheckedAt}`)}>
      <span
        className={classNames(classes.circle, {
          [classes.red]: toBeCheckedAt === ToBeCheckedAt.VERY_SOON,
          [classes.orange]: toBeCheckedAt === ToBeCheckedAt.SOON,
          [classes.green]: toBeCheckedAt === ToBeCheckedAt.FUTURE,
        })}
      />
    </Tooltip>
  ) : null;
};

const useStyles = makeStyles({
    circle: {
        display: 'inline-block',
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: 'red',
    },
    red: {
        backgroundColor: '#dd0600',
    },
    orange: {
        backgroundColor: '#ffcb00',
    },
    green: {
        backgroundColor: '#71ff0b',
    },

});

export default ToBeCheckedAtField;
