import React from 'react';
import {SelectInput, useTranslate} from "react-admin";
import CopyrightStatus from '../common/copyrightStatus';

type Props = React.ComponentProps<typeof SelectInput>;

const CopyrightInput = (props: Props) => {

    const t = useTranslate();

    const choices = Object.values(CopyrightStatus).map(status => ({
        id: status, name: t(`common.copyright.${status}`)
    }));
    return (
        <SelectInput
            {...props}
            allowEmpty={true}
            emptyValue={null}
            emptyText={'-'}
            choices={choices}
        />
    )
};

export default CopyrightInput;
