import {AppBar} from 'react-admin';
import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import LanguageSelector from "./LanguageSelector";

const CustomAppBar = (props) => {

    const classes = useStyles();

    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <LanguageSelector />
        </AppBar>
    )
}

const useStyles = makeStyles(theme => ({
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    }),
    {name: 'RaAppBar'}
);

export default CustomAppBar;
