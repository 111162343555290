import { LinearProgress, Record, useNotify, useQuery, useTranslate } from 'react-admin';
import React from 'react';
import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import { formatDate } from '../common/custom-components/utils';
import { DateBasedIcon } from '../icons';

interface Props {
  record: Record;
}

const NextUnassignedDate = (props: Props) => {
    const { record } = props;
    const classes = useStyles();
    const notify = useNotify();
    const translate = useTranslate();

    const { data, loading } = useQuery({
        type: 'fetchNextUnassignedDate',
        resource: 'daily',
        payload: { topicId: record.id, today: formatDate(new Date()) }
    }, {
        onFailure: (error) => notify(error.message, 'warning'),
    });

    const date = data ? new Date(data) : null;

    if (loading)
        return <LinearProgress />;

    if (date !== undefined) return (
      <Tooltip title={translate('resources.topic.nextUnassignedDate')}>
          <Chip
            icon={<DateBasedIcon className={classes.icon} />}
            label={date ? formatDate(date, 'dd.MM.') : '-'} />
      </Tooltip>
    );

    return null;
};

const useStyles = makeStyles({
    icon: {
        fontSize: '1.2em',
    }
});

export default NextUnassignedDate;
