import React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';

const LabelInput = ({ optionText = 'description', ...props }) => {
  return (
    <ReferenceArrayInput perPage={50} sort={{ field: 'abbreviation', order: 'ASC' }}  {...props} reference="label">
      <AutocompleteArrayInput matchSuggestion={() => true} optionText={optionText} />
    </ReferenceArrayInput>
  );
};

export default LabelInput;
