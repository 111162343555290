import { List as ReactAdminList, ListProps } from 'react-admin';
import React, { ReactElement } from "react";
import CustomPagination from './CustomPagination';
import { useSelector } from 'react-redux';
import { languageSelectors } from '../../redux/language';
import { Subject } from '../appAbilities';
import { useCheckAbilities } from '../custom-hooks';
import AuthorizedBulkActionButtons from './AuthorizedBulkActionButtons';

interface Props extends ListProps {
  subject: Subject;
  applyGlobalLanguage?: boolean;
  children: ReactElement;
}

const List = ({ applyGlobalLanguage, filter, subject, ...props }: Props) => {
  const [canExport] = useCheckAbilities(can => [can('export', subject)]);
  const selectedLanguage = useSelector(languageSelectors.selectLanguage);
  const shouldApplyFilter = applyGlobalLanguage && selectedLanguage && selectedLanguage !== 'all';

  const combinedFilter = {
    ...(shouldApplyFilter ? { lang: selectedLanguage } : {}),
    ...(filter || {}),
  };

  return (
    <ReactAdminList
      filter={combinedFilter}
      bulkActionButtons={<AuthorizedBulkActionButtons subject={subject} />}
      exporter={canExport ? undefined : false}
      {...props}
    />
  );
};

List.defaultProps = {
  pagination: <CustomPagination />,
  perPage: 50,
  applyGlobalLanguage: false,
};

export default List;
