import { sanitizeFieldRestProps, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import React from 'react';

const TranslationLink = ({ label, ...rest }) => {
  const t = useTranslate();
  const restProps = sanitizeFieldRestProps(rest);
  return (
    <Typography {...restProps} variant={'overline'} style={{ lineHeight: 0 }}>
      {t(label)} →
    </Typography>
  );
};

export default TranslationLink;
