import {
  BooleanField,
  Button,
  DateField,
  EditButton,
  EditControllerProps,
  NumberField,
  Record,
  ReferenceManyField,
  TextField,
  useTranslate,
} from 'react-admin';
import {
  Aside,
  ContentStatusField,
  CustomPagination,
  DenseDatagrid,
  LinkRecordInput,
  RelatedEntitiesHeader,
  ReorderField,
  Spacer,
  ToBeCheckedAtField,
  UnlinkButton,
} from '../common/custom-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { Add, ImageOutlined as ImageIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import ToBeCheckedAt from './ToBeCheckedAt';
import NextUnassignedDate from './NextUnassignedDate';
import { formatDate } from '../common/custom-components/utils';
import { EditButtonProps } from 'ra-ui-materialui/lib/button/EditButton';
import TopicType from '../common/topicType';
import { useCheckAbilities } from '../common/custom-hooks';
import { subject } from '@casl/ability';

const optionRenderer = (record: Record) => {
  if (record) {
    const label = `${record['title']} (#${record.id})`;
    if (record.date) {
      return `${formatDate(new Date(record.date), 'dd.MM.')} - ${label}`;
    } else {
      return label;
    }
  }
  return 'Missing record';
};

interface Props {
  basePath?: EditControllerProps['basePath'];
  record: EditControllerProps['record'];
  resource: EditControllerProps['resource'];
  save: EditControllerProps['save'];
  saving: EditControllerProps['saving'];
  version: EditControllerProps['version'];
}

const RelatedDailyList = (props: Props) => {
  const [canAddChild, canCreateDaily] = useCheckAbilities(can => [
    can('addChild', subject('Topic', props.record)),
    can('create', 'Daily'),
  ]);
  const translate = useTranslate();

  const isDateBased = props.record && props.record.type === TopicType.DATE_BASED;
  const classes = useStyles();

  return (
    <Aside title={translate('resources.daily.name', 10)}>
      {props.record && (
        <>
          <RelatedEntitiesHeader>
            {canAddChild && (
              <LinkRecordInput
                resource={'daily'}
                record={props.record}
                parentResource={'daily-to-topic'}
                optionText={optionRenderer}
                filter={{
                  type: isDateBased ? undefined : TopicType.INDEX_BASED,
                  withDate: isDateBased ? true : undefined,
                  notLinkedWithTopic: props.record.id,
                  lang: props.record.language,
                }}
              />
            )}
            <Spacer />
            {props.record.toBeCheckedAt && <ToBeCheckedAt record={props.record} />}
            {isDateBased && <NextUnassignedDate record={props.record} />}
            {canAddChild && canCreateDaily && (
              <Button
                component={Link}
                to={{
                  pathname: '/daily/create',
                  search: `cameFromTopic=${props.record.id}`,
                  state: {
                    record: {
                      topicIds: [props.record.id],
                      type: props.record.type,
                      language: props.record.language,
                      quizChoices: props.record.type === TopicType.QUIZ ? [{}, {}, {}, {}] : undefined,
                    },
                  },
                }}
                label={translate('resources.daily.create')}
              >
                <Add />
              </Button>
            )}
          </RelatedEntitiesHeader>
          <ReferenceManyField
            basePath={props.basePath}
            record={props.record}
            resource={props.resource}
            pagination={<CustomPagination className={classes.pagination} />}
            perPage={50}
            key={props.version}
            addLabel={false}
            reference="daily-to-topic"
            sort={{ field: isDateBased ? 'date' : 'dt.order', order: 'ASC' }}
            target="topicId"
          >
            <DenseDatagrid label={''}>
              <TextField source="id" />
              {isDateBased ? (
                <DateField source={'date'} options={{ month: '2-digit', day: '2-digit' }} />
              ) : (
                <ReorderField
                  className={classes.reorderField}
                  sortBy={'dt.order'}
                  label={translate('resources.topic.fields.order')}
                  parentResource={'daily-to-topic'}
                  parentId={props.record.id}
                  value={record => record.order || -1}
                />
              )}
              <TextField className={classes.titleColumn} source="title" />
              <ContentStatusField source={'contentStatus'} />
              <ToBeCheckedAtField source="toBeCheckedAt" />
              <NumberField label={<ImageIcon />} sortable={false} source={'imageCount'} />
              <BooleanField source="isPublished" />
              <DailyEditButton topicId={props.record.id} />
              {canAddChild && <UnlinkButton parentResource={'daily-to-topic'} parentId={props.record.id} />}
            </DenseDatagrid>
          </ReferenceManyField>
        </>
      )}
    </Aside>
  );
};

const DailyEditButton = ({ record, topicId }: EditButtonProps & { topicId?: number | string }) =>
  record ? (
    <EditButton
      to={{
        pathname: `/daily/${encodeURIComponent(record.id)}`,
        search: topicId ? `cameFromTopic=${topicId}` : undefined,
      }}
    />
  ) : null;

const useStyles = makeStyles({
  pagination: {
    float: 'right',
  },
  notice: {
    marginRight: 8,
  },
  reorderField: {
    maxWidth: 50,
  },
  titleColumn: {
    display: 'block',
    minWidth: '100px',
  },
});

export default RelatedDailyList;
