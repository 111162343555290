import React, { cloneElement, useMemo } from 'react';
import {
  CreateButton,
  ExportButton,
  ListActionsProps,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  useResourceContext,
  useResourceDefinition,
} from 'react-admin';

type CreateButtonProps = React.ComponentProps<typeof CreateButton>;
type Props = ListActionsProps & { createButton?: React.ReactElement<CreateButtonProps> };

const CustomListActions = (props: Props) => {
  const { className, exporter, filters, createButton, ...rest } = props;
  const { currentSort, displayedFilters, filterValues, basePath, selectedIds, showFilter, total } = useListContext(
    props
  );
  const resource = useResourceContext(rest);
  const { hasCreate } = useResourceDefinition(rest);

  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {hasCreate &&
          (createButton ? React.cloneElement(createButton, { basePath }) : <CreateButton basePath={basePath} />)}
        {exporter !== false && (
          <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} />
        )}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

CustomListActions.defaultProps = {
  selectedIds: [],
  // @ts-ignore
  onUnselectItems: () => null,
};

export default CustomListActions;
