import { Filter, FilterProps, NullableBooleanInput, SearchInput, useTranslate } from 'react-admin';
import React from 'react';
import {
  BelongsToFilter,
  ContentStatusFilter,
  LabelInput,
  TopicPurposeInput,
  TopicTypeInput,
} from '../common/custom-components';

const TopicFilter = (props: Omit<FilterProps, 'children'>) => {
  const t = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput placeholder={t('action.search_title')} source="q" alwaysOn />
      <LabelInput source="labelIds" optionText={'abbreviation'} alwaysOn />
      <LabelInput source="withoutLabelIds" optionText={'abbreviation'} />
      <TopicTypeInput source={'type'} />
      <ContentStatusFilter source={'contentStatus'} />
      <SearchInput placeholder={t('action.search_fulltext_with_note')} source="fullTextWithNote" />
      <SearchInput placeholder={t('action.search_fulltext_only_note')} source="fullTextOnlyNote" />
      <TopicPurposeInput source={'purpose'} />
      <NullableBooleanInput source={'hasTranslation'} />
      <NullableBooleanInput source={'hasQuiz'} />
      <NullableBooleanInput source={'isPublished'} />
      <BelongsToFilter source={'subcategoryIds'} reference={'subcategory'} />
    </Filter>
  );
};

export default TopicFilter;
