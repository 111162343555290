import React from 'react';
import Edit from "../common/custom-components/Edit";
import TopicForm from "./TopicForm";
import RelatedDailyList from "./RelatedDailyList";


const TopicEdit = props => {

    return (
        <Edit {...props} aside={<RelatedDailyList/> }>
            <TopicForm redirect={false} />
        </Edit>
    );
};

export default TopicEdit;
