import React, { useCallback } from 'react';
import { FieldTitle, useInput, InputProps } from 'react-admin';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, getYear, setYear } from 'date-fns';
import { DatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';

interface Props extends InputProps<Partial<DatePickerProps>> {
  dateOnly?: boolean;
  providerOptions?: {
    utils: any;
    locale?: any;
    libInstance?: any;
  };
  isRequired?: boolean;
  containerClassName?: string;
}

const CustomDateInput = ({
  options,
  label,
  source,
  resource,
  className,
  containerClassName,
  isRequired,
  providerOptions,
  dateOnly,
  ...rest
}: Props) => {
  const { input } = useInput({ source, resource, allowNull: true, ...rest });

  const handleChange = useCallback(
    (value: Date | null) => {
      if (dateOnly) {
        input.onChange(toReferenceDate(value));
      } else {
        input.onChange(value?.toISOString());
      }
    },
    [dateOnly, input]
  );


  return (
    <div className={containerClassName}>
      <MuiPickersUtilsProvider {...providerOptions}>
        <DatePicker
          {...options}
          clearable={true}
          label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
          margin={'dense'}
          inputVariant={'filled'}
          className={className}
          value={dateOnly ? fromReferenceDate(input.value) : input.value}
          onChange={handleChange}
          onBlur={input.onBlur}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

function fromReferenceDate(dateString: string | null): string | null {
  if (dateString) {
    const date = new Date(dateString);
    const currentYear = getYear(new Date());
    const referenceDate = setYear(date, currentYear);
    return formatDate(referenceDate);
  }
  return null;
}

function toReferenceDate(date: Date | null): string | null {
  if (date) {
    return formatDate(setYear(date, 2020));
  }
  return null;
}

function formatDate(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

CustomDateInput.defaultProps = {
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export default CustomDateInput;
