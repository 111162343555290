import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import React from 'react';
import { useSelector } from 'react-redux';
import { languageSelectors } from '../../redux/language';
import { titleWithFlagRenderer } from '../optionRendererFactory';

const BelongsToFilter = ({ source, reference, ...other }) => {
    const selectedLanguage = useSelector(languageSelectors.selectLanguage);

    return (
      <ReferenceArrayInput
        {...other}
        source={source}
        reference={reference}
        filter={selectedLanguage !== 'all' ? { lang: selectedLanguage } : undefined}
      >
          <AutocompleteArrayInput allowEmpty={false} optionText={titleWithFlagRenderer} />
      </ReferenceArrayInput>
    );
};

export default BelongsToFilter;
