import { Record, ReferenceField } from "react-admin";
import React from "react";
import {FunctionChipField} from "./FunctionChipField";
import { ReferenceFieldProps } from "ra-ui-materialui/lib/field/ReferenceField";

interface Props extends Omit<ReferenceFieldProps, 'children'> {
    optionTextRenderer: (record: Record, source: string) => React.ReactNode;
}

const RelatedEntityField = ({optionTextRenderer, ...props}: Props) => {
    return (
        <ReferenceField {...props}>
            <FunctionChipField size={'medium'} render={optionTextRenderer}/>
        </ReferenceField>
    );
}

RelatedEntityField.defaultProps = {
    addLabel: true,
};

export default RelatedEntityField;
