import { fetchUtils } from 'react-admin';
import { Options } from './dataProvider';
import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyA3x7NasPMLCqGaHBcHke-Qfwjhg_fmhlw',
  authDomain: 'loyal-network-261213.firebaseapp.com',
  databaseURL: 'https://loyal-network-261213.firebaseio.com',
  projectId: 'loyal-network-261213',
  storageBucket: 'loyal-network-261213.appspot.com',
  messagingSenderId: '249921326699',
  appId: '1:249921326699:web:1a344966212aa5c07ceb2e',
  measurementId: 'G-5YD6JVHRBM',
};

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/';

export const initFirebase = () => {
  firebase.initializeApp(firebaseConfig);
};

export const httpClient = async (url: string, options: Options = {}) => {
  const absoluteUrl = new URL(url, apiUrl);
  const headers = new Headers(options.headers ? options.headers : { Accept: 'application/json' });

  const currentUser = await getCurrentUser();
  if (currentUser === null) {
    return;
    //return Promise.reject(new HttpError("You aren't logged in", 403));
  }
  const token = await currentUser.getIdToken();
  headers.set('Authorization', `Bearer ${token}`);

  options.headers = headers;
  return fetchUtils.fetchJson(absoluteUrl.href, options);
};

export function getCurrentUser(): Promise<firebase.User | null> {
  const auth = firebase.auth();
  return new Promise(resolve => {
    if (auth.currentUser) return resolve(auth.currentUser);
    const unsubscribe = firebase.auth().onAuthStateChanged(
      user => {
        unsubscribe();
        resolve(user);
      },
      e => {
        console.error(e);
        resolve(null);
      }
    );
  });
}
