import lodashMemoize from 'lodash/memoize';
import { composeSyncValidators, Validator } from 'react-admin';
import { AppAbility, Subject } from './appAbilities';
import { subject } from '@casl/ability';

// See: https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/form/validate.ts

type Memoize = <T extends (...args: any[]) => any>(func: T, resolver?: (...args: any[]) => any) => T;

export const memoize: Memoize = (fn: any) => lodashMemoize(fn, (...args) => JSON.stringify(args));

export const isEmpty = (value: any) =>
  typeof value === 'undefined' || value === null || value === '' || (Array.isArray(value) && value.length === 0);

/**
 * @param validators Validators that will be applied if record is published
 */
export const requiredForPublishing = (...validators: Validator[]): Validator => (value, values, meta) => {
  const validator = composeSyncValidators(validators);
  return values.isPublished === true ? validator(value, values, meta) : undefined;
};

export const isValue = memoize(
  (requiredValue, message = `Must be ${requiredValue}`): Validator => (value, values) => {
    return value !== requiredValue ? message : undefined;
  }
);

export const allowed = memoize(
  (abilities: AppAbility, subjectType: Subject, fieldName?: string): Validator => (value, values) => {
    return abilities.can('update', subject(subjectType, values), fieldName) ? undefined : 'common.missing_permission';
  }
);

export const validFilename: Validator = memoize(value => {
  const validateFile = (value: any) => {
    if (
      value &&
      value.rawFile &&
      !/^[0-9A-Za-z\-_]+[0-9A-Za-z\-_ ]*[0-9A-Za-z\-_]+\.(?:jpg|jpeg|png)$/.test(value.rawFile.name)
    ) {
      return 'Filename contains illegal characters has unsupported file-format';
    }
    return undefined;
  };

  if (Array.isArray(value)) {
    return value.map(validateFile).find(m => !!m);
  } else {
    return validateFile(value);
  }
});