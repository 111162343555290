import React from 'react';
import { BooleanField, Datagrid, DateField, ListProps, ReferenceField, TextField } from 'react-admin';
import { IsTranslatedField, LanguageField, List, TitleFilter } from '../common/custom-components';

type Props = ListProps;

const CategoryList = (props: Props) => {
  return (
    <List {...props} subject={'Category'} applyGlobalLanguage={true} filters={<TitleFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source={'language'} reference={'language'}>
          <LanguageField />
        </ReferenceField>
        <TextField source={'title'} />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <IsTranslatedField />
        <BooleanField source={'isPublished'} />
      </Datagrid>
    </List>
  );
};

export default CategoryList;
