import * as React from 'react';
import { Box } from '@material-ui/core';
import { BooleanInput, Record, required, RichTextField, TextField, TextInput } from 'react-admin';
import { ImageField, ImageInput, RichTextInput, WrapInput } from '../../common/custom-components';
import { validFilename } from '../../common/validators';
import { useCheckAbilities } from '../../common/custom-hooks';
import { subject } from '@casl/ability';
import TopicType from '../../common/topicType';
import QuizChoiceArrayInput from '../quiz/QuizChoiceArrayInput';

interface Props {
  basePath: string;
  record: Record;
  resource: string;
}

const ContentSection = (props: Props) => {
  const { ...fieldProps } = props;
  const isQuizDaily = props.record.type === TopicType.QUIZ;

  const [canUpdateContent] = useCheckAbilities(can => [can('update', subject('Daily', props.record), 'content')]);

  return (
    <Box>
      <Box display={'flex'}>
        <WrapInput {...fieldProps}>
          {canUpdateContent ? <TextInput source="title" validate={required()} /> : <TextField source={'title'} />}
        </WrapInput>
        {!isQuizDaily && (
          <WrapInput {...fieldProps} ml={2}>
            <BooleanInput source="showTitleInTeaser" disabled={!canUpdateContent} />
          </WrapInput>
        )}
      </Box>
      <WrapInput {...fieldProps}>
        {canUpdateContent ? <RichTextInput source="teaser" /> : <RichTextField source={'teaser'} />}
      </WrapInput>
      {isQuizDaily ? (
        <WrapInput {...fieldProps} formInputClassName={null}>
          <QuizChoiceArrayInput source={'quizChoices'} />
        </WrapInput>
      ) : (
        <WrapInput {...fieldProps}>
          {canUpdateContent ? <RichTextInput source="text" /> : <RichTextField source={'text'} />}
        </WrapInput>
      )}
      <WrapInput {...fieldProps}>
        {canUpdateContent ? <RichTextInput source="solution" /> : <RichTextField source={'solution'} />}
      </WrapInput>

      <WrapInput {...fieldProps}>
        {canUpdateContent ? (
          <ImageInput validate={validFilename} source={'mainImages'} multiple={true}>
            <ImageField source={'url'} filename={'filename'} />
          </ImageInput>
        ) : (
          <ImageField source={'mainImages'} src={'url'} filename={'filename'} />
        )}
      </WrapInput>

      <WrapInput {...fieldProps}>
        {canUpdateContent ? (
          <ImageInput validate={validFilename} source={'solutionImages'} multiple={true}>
            <ImageField source={'url'} filename={'filename'} />
          </ImageInput>
        ) : (
          <ImageField source={'solutionImages'} src={'url'} filename={'filename'} />
        )}
      </WrapInput>
    </Box>
  );
};

export default ContentSection;
