import React from 'react';
import { Confirm, FormContext, useNotify, useTranslate, SaveButton } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import { Theme } from '@material-ui/core/styles';

interface Props extends React.ComponentProps<typeof SaveButton> {
  confirmationTitle: string;
  confirmationMessage: string;
  withConfirmation?: boolean;
  pristine?: boolean;
}

const SaveWithConfirmButton = (props: Props) => {
  const {
    classes: classesOverride,
    withConfirmation = true,
    confirmationTitle,
    confirmationMessage,
    invalid,
    label = 'ra.action.save',
    disabled,
    pristine,
    redirect,
    saving,
    submitOnEnter,
    variant = 'contained',
    handleSubmitWithRedirect,
    ...rest
  } = props;

  const classes = useStyles(props);
  const notify = useNotify();
  const translate = useTranslate();
  const { setOnSave } = React.useContext(FormContext);

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const execute = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setOnSave();
    if (saving) {
      // prevent double submission
      event.preventDefault();
    } else {
      if (invalid) {
        notify('ra.message.invalid_form', 'warning');
      }
      // always submit form explicitly regardless of button type
      if (event) {
        event.preventDefault();
      }
      handleSubmitWithRedirect(redirect);
      closeDialog();
    }
  };

  const displayedLabel = label && translate(label, { _: label });
  return (
    <>
      <Button
        className={classes.button}
        variant={variant}
        type={'button'}
        onClick={withConfirmation ? openDialog : execute}
        color={saving ? 'default' : 'primary'}
        aria-label={displayedLabel}
        disabled={disabled === undefined ? pristine : disabled}
        {...sanitizeButtonRestProps(rest)}
      >
        {saving ? (
          <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
        ) : (
          <SaveIcon className={classNames(classes.leftIcon, classes.icon)} />
        )}
        {displayedLabel}
      </Button>
      <Confirm
        isOpen={isDialogOpen}
        loading={saving}
        title={confirmationTitle}
        content={confirmationMessage}
        onConfirm={execute}
        onClose={closeDialog}
      />
    </>
  );
};

const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  record,
  redirect,
  resource,
  saving,
  onSave,
  submitOnEnter,
  undoable,
  ...rest
}: any) => rest;

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'SaveWithConfirmButton' }
);

export default SaveWithConfirmButton;
