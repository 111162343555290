import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { Loading, useQuery, useTranslate } from 'react-admin';
import { formatPercentage } from '../common/formatter';

interface IProps {}

const UserCount = (props: IProps) => {
  const t = useTranslate();

  const { data, loading, error } = useQuery(
    {
      type: 'fetchCounts',
      resource: 'user',
      payload: {},
    },
    {}
  );

  if (loading) return <Loading />;

  if (error) {
    return <Typography color={'error'}>{error.message}</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('resources.user.stats.language')}</TableCell>
            <TableCell align="right">{t('resources.user.stats.count')}</TableCell>
            <TableCell align="right">{t('resources.user.stats.share')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.usersPerLanguage.map((userCount: any) => (
            <TableRow key={userCount.language}>
              <TableCell>{userCount.language}</TableCell>
              <TableCell align="right">{userCount.userCount}</TableCell>
              <TableCell align="right">{formatPercentage(userCount.share)}</TableCell>
            </TableRow>
          ))}
          <TableRow key={'total'}>
            <TableCell>Total</TableCell>
            <TableCell align="right">{data.totalUserCount}</TableCell>
            <TableCell align="right">{formatPercentage(1)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserCount;
