import React from 'react';
import { BulkActionProps, BulkDeleteButton } from 'react-admin';
import { Subject } from '../appAbilities';
import { useCheckAbilities } from '../custom-hooks';

type Props = { subject: Subject } & BulkActionProps;

const AuthorizedBulkActionButtons = ({ subject, ...props }: Props) => {
  const [canDelete] = useCheckAbilities(can => [can('delete', subject)]);

  return <>{canDelete && <BulkDeleteButton undoable={false} {...props} />}</>;
};

export default AuthorizedBulkActionButtons;
