import React from 'react';
import {Create,} from 'react-admin';
import LabelForm from "./LabelForm";


const LabelCreate = props => (
    <Create {...props}>
        <LabelForm/>
    </Create>
);

export default LabelCreate;
