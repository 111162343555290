import React from 'react';
import { Button, DeleteButton, Toolbar, useTranslate } from 'react-admin';
import { QuizifyButton, SaveWithConfirmButton, Spacer, TranslateButton } from '../common/custom-components';
import { useCheckAbilities } from '../common/custom-hooks';
import { subject } from '@casl/ability';
import NeighborDailyButton from './NeighborDailyButton';
import { Link } from 'react-router-dom';
import ContentAdd from '@material-ui/icons/Add';
import { RedirectionSideEffect } from 'ra-core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  withConfirmation: boolean;
  topicId?: number;
} & React.ComponentProps<typeof Toolbar>;

const SaveDailyToolbar = ({ withConfirmation, topicId, redirect: _redirect, ...props }: Props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { record } = props;

  const redirect: RedirectionSideEffect =
    _redirect === 'edit' && topicId !== undefined
      ? (basepath, id) => {
          return `${basepath}/${id}?cameFromTopic=${topicId}`;
        }
      : _redirect;

  const [canCreate, canSave, canDelete, canTranslate] = useCheckAbilities(can => [
    can('create', 'Daily'),
    can('update', 'Daily'),
    can('delete', 'Daily'),
    can('createTranslation', subject('Daily', record)),
  ]);

  return (
    <Toolbar redirect={redirect} {...props}>
      {canSave && (
        <SaveWithConfirmButton
          redirect={redirect}
          withConfirmation={withConfirmation}
          confirmationTitle={translate('resources.daily.confirmation.title')}
          confirmationMessage={translate('resources.daily.confirmation.message')}
        />
      )}
      {typeof record.id !== 'undefined' && topicId && (
        <>
          <NeighborDailyButton
            className={classes.buttonMargin}
            dailyId={props.record.id}
            topicId={topicId}
            direction={'prev'}
          />
          <NeighborDailyButton dailyId={props.record.id} topicId={topicId} direction={'next'} />
        </>
      )}
      {typeof record.id !== 'undefined' && canCreate && topicId && (
        <Button
          component={Link}
          className={classes.buttonMargin}
          to={{
            pathname: `${props.basePath}/create`,
            search: `cameFromTopic=${topicId}`,
            state: { record: { type: record.type, topicIds: [topicId], language: record.language } },
          }}
          alignIcon={'right'}
          label={translate('action.add_new')}
        >
          <ContentAdd />
        </Button>
      )}
      <Spacer />

      {canTranslate && record && record.translationOfId === null && record.translatedById === null && (
        <TranslateButton allowChildCopy={false} />
      )}
      {canTranslate && record && record.quizId === null && record.quizForId === null && (
        <QuizifyButton allowChildCopy={false} />
      )}
      {canDelete && record && typeof record.id !== 'undefined' && <DeleteButton mutationMode={'optimistic'} />}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonMargin: {
      marginLeft: theme.spacing(2),
    },
  })
);

export default SaveDailyToolbar;
