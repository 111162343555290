import React from 'react';
import Edit from "../common/custom-components/Edit";
import LabelForm from "./LabelForm";

const LabelEdit = props => {

    return (
        <Edit {...props} >
            <LabelForm/>
        </Edit>
    );
};

export default LabelEdit;
