import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Button, ButtonProps, Loading, Record, useMutation } from 'react-admin';
import { QuizIcon } from '../icons';
import { useTranslate } from 'ra-core';
import { formatPercentage } from '../common/formatter';
import TopicType from '../common/topicType';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface Props extends ButtonProps {
  record?: Record;
}

const QuizStatisticsButton = ({ record, ...rest }: Props) => {
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStyles();
  const t = useTranslate();

  const [loadData, { data, loading, error }] = useMutation();

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    loadData({
      type: 'fetchQuizStatistics',
      resource: 'topic',
      payload: { id: record.id },
    });
    setOpen(true);
  };

  if (record.type !== TopicType.QUIZ) {
    return null;
  }

  return (
    <>
      <Button disabled={!record?.id} label={'action.quiz_statistics'} onClick={onClick} {...(rest as any)}>
        <QuizIcon />
      </Button>

      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        {data && <DialogTitle>{data.topicTitle}</DialogTitle>}
        <DialogContent>
          {loading && <Loading />}
          {error && <Typography color={'error'}>{error.message}</Typography>}
          {data && (
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('resources.subscription.quizStatistics.dailyId')}</TableCell>
                  <TableCell>{t('resources.subscription.quizStatistics.dailyTitle')}</TableCell>
                  <TableCell align={'right'}>{t('resources.subscription.quizStatistics.correctAnswers')}</TableCell>
                  <TableCell align={'right'}>{t('resources.subscription.quizStatistics.totalAnswers')}</TableCell>
                  <TableCell align={'right'}>{t('resources.subscription.quizStatistics.averageCorrect')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.bold}>{t('resources.subscription.quizStatistics.total')}</TableCell>
                  <TableCell className={classes.bold} align={'right'}>
                    {data.correctAnswers}
                  </TableCell>
                  <TableCell className={classes.bold} align={'right'}>
                    {data.totalAnswers}
                  </TableCell>
                  <TableCell className={classes.bold} align={'right'}>
                    {formatPercentage(data.correctPercentage)}
                  </TableCell>
                </TableRow>
                {data.dailyAnswerStatistics.map((row: any) => (
                  <TableRow key={row.dailyId}>
                    <TableCell>{row.dailyId}</TableCell>
                    <TableCell>{row.dailyTitle}</TableCell>
                    <TableCell align={'right'}>{row.correctAnswers}</TableCell>
                    <TableCell align={'right'}>{row.totalAnswers}</TableCell>
                    <TableCell align={'right'}>{formatPercentage(row.correctPercentage)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

export default QuizStatisticsButton;
