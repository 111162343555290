import * as React from 'react';
import { Button, Confirm, Record, useMutation, useNotify, useRedirect, useTranslate } from 'react-admin';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  allowChildCopy?: boolean;
  resource?: string;
  basePath?: string;
  record?: Record;
  saving?: boolean;
  mutationType: string;
  actionLabel: string;
}

const CopyButton = ({
  allowChildCopy = false,
  mutationType,
  actionLabel,
  resource,
  basePath,
  record,
  saving,
}: Props) => {
  const t = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate, { loading }] = useMutation();

  const [dialogConfig, setDialogConfig] = React.useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const callApi = (withChildren: boolean) =>
    mutate(
      {
        type: mutationType,
        resource,
        payload: { id: record.id, withChildren },
      },
      {
        onFailure: error => notify(error.message, 'warning'),
        onSuccess: ({ data: id }) => redirect('edit', basePath, id),
      }
    );

  const copy = () => {
    const { withChildren } = dialogConfig;
    setDialogConfig(undefined);
    closeMenu();

    callApi(withChildren);
  };

  const showDialog = (withChildren: boolean) => {
    setDialogConfig({ withChildren });
  };

  const handleClick = (event: React.MouseEvent) => {
    if (allowChildCopy) {
      setAnchorEl(event.currentTarget);
    } else {
      showDialog(false);
    }
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button className={classes.button} label={actionLabel} onClick={handleClick}>
        {loading ? (
          <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
        ) : (
          <FileCopyIcon className={classNames(classes.leftIcon, classes.icon)} />
        )}
      </Button>

      {allowChildCopy && (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
          <MenuItem onClick={() => showDialog(false)}>{t('common.copy.without_children')}</MenuItem>
          <MenuItem onClick={() => showDialog(true)}>{t('common.copy.with_children')}</MenuItem>
        </Menu>
      )}

      <Confirm
        isOpen={dialogConfig !== undefined}
        loading={saving}
        title={
          `${actionLabel} ` +
          t(`common.copy.confirm.${dialogConfig?.withChildren ? 'title_with_children' : 'title_without_children'}`)
        }
        content={'common.copy.confirm.content'}
        onConfirm={copy}
        onClose={() => setDialogConfig(undefined)}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
  },
}));

export type CopyButtonProps = Props;

export default CopyButton;
