import React from 'react';
import { Filter, FilterProps, SearchInput, useTranslate } from 'react-admin';
import { LabelInput, TopicTypeInput } from "../common/custom-components";

const SubscriptionFilter = (props: Omit<FilterProps, 'children'>) => {
  const t = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput placeholder={t('action.search_title')} source="q" alwaysOn />
      <TopicTypeInput source={'type'} />
      <LabelInput source="labelIds" optionText={'abbreviation'} alwaysOn />
      <LabelInput source="withoutLabelIds" optionText={'abbreviation'} />
    </Filter>
  );
};

export default SubscriptionFilter;
