import React, { FC } from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardMenuItem, getResources, MenuItemLink, MenuProps, ReduxState, Translate } from 'react-admin';
import { Theme, useMediaQuery } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import classnames from 'classnames';
import DefaultIcon from '@material-ui/icons/ViewList';
import inflection from 'inflection';
import { Face } from '@material-ui/icons';
import lodashGet from 'lodash/get';
import { useCheckAbilities } from '../common/custom-hooks';

const translatedResourceName = (resource: any, translate: Translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const Menu: FC<MenuProps> = props => {
  const { classes: classesOverride, className, dense, hasDashboard, onMenuClick, logout, ...rest } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual) as Array<any>;

  // Used to force redraw on navigation
  useSelector((state: ReduxState) => state.router.location.pathname);

  const [canViewStatistics] = useCheckAbilities(can => [can('view', 'Statistics')]);

  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      {hasDashboard && <DashboardMenuItem onClick={onMenuClick} dense={dense} sidebarIsOpen={open} />}
      {resources
        .filter(r => r.hasList)
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={translatedResourceName(resource, translate)}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        ))}
      {canViewStatistics && (
        <MenuItemLink
          key={'users'}
          to={'/users'}
          primaryText={translate('resources.user.title')}
          leftIcon={<Face />}
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {isXSmall && logout}
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '1.5em',
      },
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
  }),
  { name: 'RaMenu' }
);

export default Menu;
