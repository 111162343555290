enum AdminRole {
    ADMIN = 'admin',
    MANAGER = 'manager',
    PUBLISHER = 'publisher',
    SENIOR_WRITER = 'seniorWriter',
    WRITER = 'writer',
    READER = 'reader',
}

export default AdminRole;
