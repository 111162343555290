import { Button, EditButton, Record, ReferenceManyField, TextField, useTranslate } from 'react-admin';
import {
  Aside,
  CustomPagination,
  DenseDatagrid,
  LinkRecordInput,
  RelatedEntitiesHeader,
  Spacer,
  UnlinkButton,
} from '../common/custom-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { Add } from '@material-ui/icons';
import { titleRenderer } from '../common/optionRendererFactory';
import { makeStyles } from '@material-ui/core';
import { useCheckAbilities } from '../common/custom-hooks';

interface Props {
  basePath?: string;
  record?: Record;
  resource?: string;
  version?: number;
}

const RelatedTopicList = ({ basePath, record, resource, version, ...other }: Props) => {
  const [canCreateTopic, canAddChild] = useCheckAbilities(can => [
    can('create', 'Topic'),
    can('addChild', 'Subcategory'),
  ]);
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Aside title={translate('resources.topic.name', 10)}>
      {record && (
        <>
          <RelatedEntitiesHeader>
            {canAddChild && (
              <LinkRecordInput
                resource={'topic'}
                parentResource={resource}
                record={record}
                optionText={titleRenderer}
                filter={{
                  lang: record.language,
                  notLinkedWithSubcategory: record.id,
                }}
              />
            )}
            <Spacer />
            {canCreateTopic && canAddChild && (
              <Button
                component={Link}
                to={{
                  pathname: '/topic/create',
                  state: { record: { subcategoryIds: [record.id] } },
                }}
                label={translate('resources.topic.create')}
              >
                <Add />
              </Button>
            )}
          </RelatedEntitiesHeader>

          <ReferenceManyField
            key={version}
            basePath={basePath}
            record={record}
            resource={resource}
            pagination={<CustomPagination className={classes.pagination} />}
            addLabel={false}
            reference="topic"
            target="subcategoryId"
          >
            <DenseDatagrid>
              <TextField source="id" />
              <TextField source="title" />
              <EditButton />
              {canAddChild && <UnlinkButton parentResource={resource} parentId={record.id} />}
            </DenseDatagrid>
          </ReferenceManyField>
        </>
      )}
    </Aside>
  );
};

const useStyles = makeStyles({
  pagination: {
    float: 'right',
  },
});

export default RelatedTopicList;
