import React from 'react';
import Edit from "../common/custom-components/Edit";
import SubcategoryForm from "./SubcategoryForm";
import RelatedTopicList from "./RelatedTopicList";


export const SubcategoryEdit = props => {

    return (
        <Edit {...props} aside={<RelatedTopicList/>}>
            <SubcategoryForm redirect={false}/>
        </Edit>
    );
};

export default SubcategoryEdit;
