import { AutocompleteInput, OptionText, Record as RaRecord, ReferenceField, ReferenceInput } from 'react-admin';
import React from 'react';
import ReferenceLink from '../ReferenceLink';

//More properties might be passed by react-admin
interface Props {
  disabled?: boolean;
  addLabel?: boolean;
  label: string;
  className?: string;
  basePath?: string;
  source: string;
  record?: RaRecord;
  optionTextRenderer: OptionText;
  reference: string;
  filter?: Record<string, any>;
}

const RelatedEntityInput = ({ optionTextRenderer, filter, label, ...props }: Props) => {
  return (
    <>
      <ReferenceField {...props}>
        <ReferenceLink label={label} />
      </ReferenceField>
      <ReferenceInput {...props} allowEmpty={true} filter={filter}>
        <AutocompleteInput resettable={true} optionText={optionTextRenderer} />
      </ReferenceInput>
    </>
  );
};

RelatedEntityInput.defaultProps = {
  addLabel: false,
};

export default RelatedEntityInput;
