import React from 'react';
import { SelectArrayInput, useTranslate } from 'react-admin';
import ContentStatus from '../contentStatus';

const ContentStatusFilter = (props: React.ComponentProps<typeof SelectArrayInput>) => {
  const t = useTranslate();

  const choices = Object.values(ContentStatus).map(status => ({
    id: status,
    name: t(`common.contentStatus.${status}`),
  }));

  return <SelectArrayInput {...props} choices={choices} />;
};

export default ContentStatusFilter;
