import React from 'react';
import { FunctionField, ReferenceArrayField, SingleFieldList, Record } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { ReferenceArrayFieldProps } from 'ra-ui-materialui/lib/field/ReferenceArrayField';

interface Props extends Omit<ReferenceArrayFieldProps, 'children'> {
  optionRenderer: (record?: Record, source?: string) => any;
}

const BelongsToMany = ({ optionRenderer, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <ReferenceArrayField {...rest} className={classes.links}>
      <SingleFieldList linkType={'edit'}>
        <FunctionField render={optionRenderer} />
      </SingleFieldList>
    </ReferenceArrayField>
  );
};

const useStyles = makeStyles({
  links: {
    display: 'block',
    margin: 0,
    '& > *': {
      marginRight: 4,
    },
  },
});

export default BelongsToMany;
