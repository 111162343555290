import React from 'react';
import { Datagrid, DateField, ListProps, TextField } from 'react-admin';
import { List } from '../common/custom-components';

type Props = ListProps;

const AdminList = (props: Props) => {
  return (
    <List {...props} subject={'Admin'}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="initials" />
        <TextField source="email" />
        <TextField source="role" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default AdminList;
