import { InfoNotice, List } from '../common/custom-components';
import { Datagrid, ListProps, TextField, useTranslate } from 'react-admin';
import React from 'react';
import SubscriptionDurationButton from './SubscriptionDurationButton';
import SubscriptionFilter from './SubscriptionFilter';
import QuizStatisticsButton from './QuizStatisticsButton';
import RatingStatisticsButton from "./RatingStatisticsButton";

const SubscriptionList = (props: ListProps) => {
  const t = useTranslate();

  return (
    <List subject={'Statistics'} applyGlobalLanguage={true} filters={<SubscriptionFilter />} {...props}>
      <>
        <Datagrid>
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="runningCount" />
          <TextField source="completedCount" />
          <TextField source="canceledCount" />
          <SubscriptionDurationButton />
          <QuizStatisticsButton />
          <RatingStatisticsButton />
        </Datagrid>
        <InfoNotice text={t('resources.subscription.notice')} />
      </>
    </List>
  );
};

export default SubscriptionList;
