import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { Theme } from '@material-ui/core';
import {
  ArrayInput,
  BooleanInput,
  InputProps,
  maxLength,
  minLength,
  required,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { memoize, requiredForPublishing } from '../../common/validators';
import { filter } from 'lodash';
import { useFormState } from 'react-final-form';

interface Props extends InputProps {
  disabled?: boolean;
}

const QuizChoiceArrayInput = (props: Props) => {
  const classes = useStyles();

  const { values } = useFormState();
  const minLengthValidator = requiredForPublishing(minLength(4, 'common.validation.min_length'));

  return (
    <ArrayInput
      validate={[minLengthValidator, exactlyOneCorrect(), maxLength(5, 'common.validation.max_length')]}
      {...props}
    >
      <SimpleFormIterator className={classes.background} disableAdd={values.quizChoices?.length >= 5}>
        <TextInput className={classes.choiceText} source={'choice'} validate={required()} />
        <BooleanInput source={'isCorrect'} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const exactlyOneCorrect = memoize(() => (value: any[]) => {
  return filter(value, element => element?.isCorrect === true).length === 1
    ? undefined
    : 'resources.daily.validation.exactly_one_correct';
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: '#e7f0dd',
    },
    choiceText: {
      width: '100%',
    },
  })
);

export default QuizChoiceArrayInput;
