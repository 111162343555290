import React from 'react';
import {Create,} from 'react-admin';
import SubcategoryForm from "./SubcategoryForm";


const defaultSubcategory = {
    language: 'de',
    isPublished: false
};

const SubcategoryCreate = props => {

    return (
        <Create {...props}>
            <SubcategoryForm initialValues={defaultSubcategory}/>
        </Create>
    );
};

export default SubcategoryCreate;
