import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import React from 'react';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/styles';
import { Link as LinkIcon } from '@material-ui/icons';

const LinkRecordInput = props => {
  const { record, parentResource, resource, optionText, filter } = props;

  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const classes = useStyles();

  const onSubmit = (values, form) => {
    const relationIds = values['relationId'];

    dataProvider
      .associate(parentResource, {
        data: {
          mode: 'link',
          recordId: record.id,
          relationIds: [relationIds],
        },
      })
      .then(refresh)
      .catch(error => notify(error.message, 'warning'));

    setTimeout(form.reset);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form className={classes.container}>
          <ReferenceInput
            source={'relationId'}
            reference={resource}
            sort={{ field: 'id', order: 'DESC' }}
            filter={filter}
          >
            <AutocompleteInput optionText={optionText} translateChoice={false} />
          </ReferenceInput>
          <Button onClick={handleSubmit} disabled={submitting || pristine} label={translate('action.link')}>
            <LinkIcon />
          </Button>
        </form>
      )}
    />
  );
};


const useStyles = makeStyles(theme => ({
    container: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    fieldContainer: {
        flowGrow: 0,
    }
}));


export default LinkRecordInput;
