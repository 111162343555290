import { usePermissions } from 'react-admin';
import { AppAbilities, AppAbility } from '../appAbilities';
import { CanParameters } from '@casl/ability/dist/types/types';
import { Ability } from '@casl/ability';

export default function useAbilities(): AppAbility {
  const { permissions } = usePermissions();
  if (permissions) {
    return permissions as AppAbility;
  } else {
    return new Ability<AppAbilities>();
  }
}

export function useCheckAbilities(
  checks: (can: (...args: CanParameters<AppAbilities>) => boolean) => boolean[]
): boolean[] {
  const abilities = useAbilities();
  return checks(abilities.can.bind(abilities));
}
