import React from 'react';
import RARichTextInput from 'ra-input-rich-text';
import { useInput } from 'react-admin';
import Quill from 'quill';
import htmlEditButton from 'quill-html-edit-button';
import { makeStyles } from "@material-ui/core/styles";

window.Quill = Quill;
Quill.register('modules/htmlEditButton', htmlEditButton);

const RichTextInput = props => {
  const { countChars, addColors, ...rest } = props;

  const {
    input: { value },
  } = useInput(rest);

  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  const [text, setText] = React.useState(stripHtml(value));
  const helpText = `${text ? text.length : 0} chars`;

  const configureQuill = quill => {
    quill.root.setAttribute('spellcheck', 'true');

    // populate quote mark dropdown
    const toolbar = quill.container.previousElementSibling;
    if (toolbar) {
      Array.prototype.slice
        .call(toolbar.querySelectorAll('.ql-marks .ql-picker-item'))
        .forEach(item => (item.textContent = item.dataset.value));
    }
  };

  return (
    <RARichTextInput
      {...rest}
      options={{
        modules: {
          htmlEditButton: {},
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'link'],
              [{ marks: ['„', '“', '“', '”', '¹', '²', '³'] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['clean'],
              addColors
                ? [
                    {
                      color: [
                        '#000000',
                        '#5c5c5c',
                        '#bf010e',
                        '#e9a600',
                        '#1cc93f',
                        '#026616',
                        '#1289c2',
                        '#012abf',
                        '#e300b6',
                        '#6c008a',
                      ],
                    },
                  ]
                : [],
            ],
            handlers: {
              marks: function (value) {
                if (value) {
                  const cursorPosition = this.quill.getSelection().index;
                  this.quill.insertText(cursorPosition, value);
                  this.quill.setSelection(cursorPosition + value.length);
                }
              },
            },
          },
          clipboard: { matchVisual: false },
        },
        formats: [
          'bold',
          'italic',
          'link',
          'underline',
          'blockquote',
          'list',
          'header',
          ...(addColors ? ['color'] : []),
        ],
      }}
      configureQuill={configureQuill}
      helperText={countChars ? helpText : undefined}
      onChange={e => setText(stripHtml(e))}
    />
  );
};

RichTextInput.defaultProps = {
  countChars: true,
};

const useStyles = makeStyles({
  '@global': {
    '.ra-input-teaser .ql-container, .ra-input-finalText .ql-container': {
      height: 150,
      maxWidth: 475,
    },
    '.ra-input-text .ql-container': {
      height: 300,
      maxWidth: 475,
    },
    '.ra-input-note .ql-container': {
      '& .ql-editor': {
        backgroundColor: 'rgba(0,0,255, 0.15)',
      },
    },
    '.ql-snow .ql-picker.ql-marks .ql-picker-label': {
      paddingRight: 16,
      '&::before': {
        content: '"Marks"',
      },
    },
  },
});

export default RichTextInput;

function stripHtml(content) {
  const div = document.createElement('div');
  div.innerHTML = content;
  return div.innerText;
}
