import React from 'react';
import get from 'lodash/get';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { FieldProps, useTranslate } from 'react-admin';
import { DateBasedIcon, IndexBasedIcon, QuizIcon } from '../../../icons';
import TopicType from '../../topicType';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const iconMap: Record<TopicType, React.ComponentType<SvgIconProps>> = {
  [TopicType.INDEX_BASED]: IndexBasedIcon,
  [TopicType.DATE_BASED]: DateBasedIcon,
  [TopicType.QUIZ]: QuizIcon,
};

const TopicTypeField = ({ className, source, record }: FieldProps) => {
  const translate = useTranslate();
  const value = record ? get(record, source) : undefined;
  const classes = useStyles();

  if (value === undefined || iconMap[value as TopicType] === undefined) {
    return <span />;
  }

  const ariaLabel = `resources.daily.${value}`;

  return (
    <Typography component="span" variant="body2" className={className}>
      <Tooltip title={translate(ariaLabel)}>
        {React.createElement(iconMap[value as TopicType], { className: classes.icon })}
      </Tooltip>
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  icon: {
    padding: theme.spacing(0.5),
    fontSize: '1.2em',
    fill: theme.palette.grey[700],
  },
}));

export default TopicTypeField;
