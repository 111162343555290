import * as React from 'react';
import { Box } from '@material-ui/core';
import { Record, required, RichTextField, TextInput } from 'react-admin';
import { requiredForPublishing, validFilename } from '../../common/validators';
import { ImageField, ImageInput, RichTextInput, WrapInput } from '../../common/custom-components';
import { useCheckAbilities } from '../../common/custom-hooks';
import { subject } from '@casl/ability';

interface Props {
  basePath: string;
  record: Record;
  resource: string;
}

const ContentSection = (props: Props) => {
  const { ...fieldProps } = props;

  const [canUpdateContent] = useCheckAbilities(can => [can('update', subject('Topic', props.record), 'content')]);
  const textValidator = requiredForPublishing(required());

  return (
    <Box>
      <WrapInput {...fieldProps}>
        <TextInput source="title" disabled={!canUpdateContent} validate={required()} />
      </WrapInput>
      <WrapInput {...fieldProps}>
        {canUpdateContent ? (
          <RichTextInput source="text" validate={textValidator} />
        ) : (
          <RichTextField source={'text'} />
        )}
      </WrapInput>
      <WrapInput {...fieldProps}>
        {canUpdateContent ? <RichTextInput source="finalText" /> : <RichTextField source={'finalText'} />}
      </WrapInput>

      <WrapInput {...fieldProps}>
        {canUpdateContent ? (
          <ImageInput validate={validFilename} source={'image'} multiple={false}>
            <ImageField source={'url'} filename={'filename'} />
          </ImageInput>
        ) : (
          <ImageField source={'image'} src={'url'} filename={'filename'} />
        )}
      </WrapInput>
    </Box>
  );
};

export default ContentSection;
