import {omit} from "lodash";
import {format} from "date-fns";

export function sanitizeRestProps(props) {
    return omit(props, [
        'addLabel',
        'allowEmpty',
        'basePath',
        'cellClassName',
        'className',
        'formClassName',
        'headerClassName',
        'label',
        'linkType',
        'link',
        'locale',
        'record',
        'resource',
        'sortable',
        'sortBy',
        'source',
        'textAlign',
        'translateChoice',
    ]);
}

export function formatDate(date, theFormat= 'yyyy-MM-dd') {
    return format(date, theFormat);
}
