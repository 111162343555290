const cacheDurationPerResource = {
    'label': 5 * 60 * 1000,
    'language': 12 * 60 * 60 * 1000,
    'subscription': 1 * 60 * 60 * 1000,
    'favourite': 1 * 60 * 60 * 1000,
    // 'category': 60 * 1000,
    // 'subcategory': 60 * 1000,
};

const applicationCache = (dataProvider) =>
    new Proxy(dataProvider, {
        get: (target, name) => (resource, params) => {
            if (name === 'getOne' || name === 'getMany' || name === 'getList' || name === 'fetchStatistics') {
                return dataProvider[name](resource, params).then(response => {
                    if (resource in cacheDurationPerResource) {
                        const duration = cacheDurationPerResource[resource];
                        const validUntil = new Date();
                        validUntil.setTime(validUntil.getTime() + duration);
                        response.validUntil = validUntil;
                    }
                    return response;
                });
            }
            return dataProvider[name](resource, params);
        },
    });

export default applicationCache;
