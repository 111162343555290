import React from 'react';
import { Create } from 'react-admin';
import TopicForm from './TopicForm';
import TopicPurpose from '../common/topicPurpose';
import { CreateProps } from 'ra-ui-materialui/lib/types';

const defaultTopic = {
  purpose: TopicPurpose.DEFAULT,
  language: 'de',
  isPublished: false,
};

const TopicCreate = (props: CreateProps) => {

    return (
        <Create {...props}>
            <TopicForm initialValues={defaultTopic}/>
        </Create>
    );
};

export default TopicCreate;
