import React from 'react';
import { BooleanField, BooleanFieldProps } from 'react-admin';
import { QuizIcon } from '../../../icons';

interface Props extends BooleanFieldProps {}

const HasQuizField = ({ record, ...other }: Props) => {
  if (!record) return null;

  const recordCopy = {
    ...record,
    isQuizOrHasQuiz: Boolean(record['quizId']) || Boolean(record['quizForId']),
  };

  return <BooleanField {...other} record={recordCopy} source={'isQuizOrHasQuiz'} />;
};

HasQuizField.defaultProps = {
  label: <QuizIcon />,
};

export default HasQuizField;
