import * as React from 'react';
import { Box } from '@material-ui/core';
import { Record, ReferenceInput, required, SelectInput } from 'react-admin';
import { titleWithFlagRenderer } from '../../common/optionRendererFactory';
import { RelatedEntityField, TranslationInput, WrapInput } from '../../common/custom-components';
import { useCheckAbilities } from '../../common/custom-hooks';
import { subject } from '@casl/ability';

interface Props {
  basePath: string;
  record: Record;
  resource: string;
}

const LanguageSection = (props: Props) => {
  const { ...fieldProps } = props;

  const [canUpdateLanguage, canUpdateTranslationOfId] = useCheckAbilities(can => [
    can('update', subject('Daily', fieldProps.record), 'language'),
    can('update', subject('Daily', fieldProps.record), 'translationOfId'),
  ]);

  return (
    <Box display={'flex'}>
      <WrapInput {...fieldProps} mr={1}>
        <ReferenceInput source="language" reference="language">
          <SelectInput disabled={!canUpdateLanguage} optionText="label" validate={required()} />
        </ReferenceInput>
      </WrapInput>
      <WrapInput {...fieldProps}>
        {props.record.translatedById ? (
          <RelatedEntityField
            reference={'daily'}
            source={'translatedById'}
            optionTextRenderer={titleWithFlagRenderer}
          />
        ) : (
          <TranslationInput
            label={'common.go_to_translation'}
            source={'translationOfId'}
            disabled={!canUpdateTranslationOfId}
            reference={'daily'}
            optionTextRenderer={titleWithFlagRenderer}
          />
        )}
      </WrapInput>
    </Box>
  );
};

export default LanguageSection;
