import React from 'react';
import {ReferenceField} from "react-admin";
import {makeStyles} from "@material-ui/core";
import {ReferenceLink} from "../index";


const BelongsToSingle = ({source, reference, label, ...rest}) => {

    const classes = useStyles();

    return (
        <ReferenceField
            {...rest}
            className={classes.links}
            label={""}
            source={source}
            reference={reference}>
            <ReferenceLink label={label}/>
        </ReferenceField>
    )
};

const useStyles = makeStyles(theme => ({
    links: {
        display: 'block',
        margin: 0,
        '& > *': {
            marginRight: 4,
        }
    }

}));


export default BelongsToSingle;
