import {Filter, SearchInput, useTranslate} from "react-admin";
import React from "react";

const TitleFilter = (props) => {
    const t = useTranslate();

    return (
        <Filter {...props}>
            <SearchInput placeholder={t('action.search_title')} source="q" alwaysOn/>
        </Filter>
    );
};

export default TitleFilter;
