import {isEmpty} from "../common/validators";

export function hasTeaserOrImage(values) {

    const errors = {};
    if (values.isPublished && isEmpty(values.teaser) && isEmpty(values.mainImages) ) {
        errors.mainImages = errors.teaser  = ['Teaser or Main Images required'];
    }
    return errors

}
