import { useTheme } from '@material-ui/styles';
import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, Theme, Typography } from '@material-ui/core';
import { Button, ButtonProps, Loading, Record, useMutation, useTranslate } from 'react-admin';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { InfoNotice } from '../common/custom-components';
import { formatPercentage } from '../common/formatter';

interface Props extends ButtonProps {
  record?: Record;
}

const SubscriptionDurationButton = ({ record, ...rest }: Props) => {
  const [isOpen, setOpen] = React.useState(false);
  const theme = useTheme<Theme>();
  const t = useTranslate();

  const [loadData, { data, loading, error }] = useMutation();

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    loadData({
      type: 'fetchSubscriptionDuration',
      resource: 'subscription',
      payload: { id: record.id },
    });
    setOpen(true);
  };

  return (
    <>
      <Button disabled={!record?.id} label={'action.seen_by_user'} onClick={onClick} {...(rest as any)}>
        <ImageEye />
      </Button>

      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        {data && <DialogTitle>{data.topicTitle}</DialogTitle>}
        <DialogContent>
          {loading && <Loading />}
          {error && <Typography color={'error'}>{error.message}</Typography>}
          {data && (
            <>
              <Typography>
                {`Ø Avg: ${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                  data.averageOverrun
                )} (${formatPercentage(data.averageOverrunPercent)})`}
              </Typography>
              <ResponsiveContainer minWidth={500} width="100%" height={300}>
                <BarChart data={data.durationDistribution}>
                  <XAxis dataKey="additionalRequiredDays" name="# Additional Days" type="number" scale="linear" />
                  <YAxis dataKey="count" name="Count" />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip
                    cursor={{ strokeDasharray: '3 3' }}
                    formatter={value => `${value} Users`}
                    labelFormatter={label => `${label} additional needed days`}
                  />
                  <Bar dataKey="count" fill={theme.palette.secondary.main} />
                </BarChart>
              </ResponsiveContainer>
              <InfoNotice text={t('resources.subscription.subscription_duration.notice')} />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionDurationButton;
