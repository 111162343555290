import get from "lodash/get";
import {Tooltip, Typography} from "@material-ui/core";
import React from "react";
import languageToFlag from "../../languageToFlag";

const LanguageField = (props) => {
    const {record} = props;
    const value = get(record, 'id');
    const label = get(record, 'label');

    return (
        <Typography
            component="span"
            variant="body2"
            className={props.className}
        >
            <Tooltip title={label}><span>{languageToFlag[value]}</span></Tooltip>
        </Typography>
    );
}
export default LanguageField;
