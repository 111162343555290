import React from 'react';
import Edit from "../common/custom-components/Edit";
import DailyForm from "./DailyForm";


const DailyEdit = props => {

    return (
        <Edit {...props}>
            <DailyForm redirect={false}/>
        </Edit>
    );
};


export default DailyEdit;
