import * as React from 'react';
import { Box } from '@material-ui/core';
import { choices, Record, required, RichTextField } from 'react-admin';
import {
  ContentStatusInput,
  CustomDateInput,
  LabelInput,
  RichTextInput,
  WrapInput,
} from '../../common/custom-components';
import { requiredForPublishing } from '../../common/validators';
import CopyrightInput from '../CopyrightInput';
import CopyrightStatus from '../../common/copyrightStatus';
import { getAllowedContentStatus } from '../../common/appAbilities';
import { useAbilities } from '../../common/custom-hooks';
import { subject } from '@casl/ability';

interface Props {
  basePath: string;
  record: Record;
  resource: string;
}

const AdminSection = (props: Props) => {
  const { ...fieldProps } = props;
  const abilites = useAbilities();

  const copyrightStatusValidator = requiredForPublishing(
    required(),
    choices([CopyrightStatus.RISK, CopyrightStatus.APPROVED, CopyrightStatus.ACCEPTABLE_RISK])
  );

  const canUpdateCopyright = abilites.can('update', subject('Daily', props.record), 'copyright');
  const canUpdateAdminNote = abilites.can('update', subject('Daily', props.record), 'adminNote');
  const canUpdateSource = abilites.can('update', subject('Daily', props.record), 'source');
  const canUpdateTbc = abilites.can('update', subject('Daily', props.record), 'toBeCheckedAt');

  const allowedContentStatus = getAllowedContentStatus(abilites, 'Daily', props.record);

  return (
    <Box>
      <WrapInput {...fieldProps}>
        <RichTextInput countChars={false} addColors={true} source={'note'} />
      </WrapInput>
      {canUpdateAdminNote && (
        <WrapInput {...fieldProps}>
          <RichTextInput countChars={false} addColors={true} source={'adminNote'} />
        </WrapInput>
      )}
      <WrapInput {...fieldProps}>
        {canUpdateSource ? (
          <RichTextInput countChars={false} addColors={true} source={'source'} />
        ) : (
          <RichTextField source={'source'} />
        )}
      </WrapInput>
      <Box display={'flex'}>
        <WrapInput {...fieldProps} mr={1}>
          <CopyrightInput disabled={!canUpdateCopyright} validate={copyrightStatusValidator} source={'copyright'} />
        </WrapInput>
        <WrapInput {...fieldProps}>
          <ContentStatusInput
            subject={'Daily'}
            source={'contentStatus'}
            disabled={allowedContentStatus.length === 0}
            allowedContentStatus={allowedContentStatus}
          />
        </WrapInput>
      </Box>
      <WrapInput {...fieldProps}>
        <CustomDateInput options={{ disabled: !canUpdateTbc }} dateOnly={false} source="toBeCheckedAt" />
      </WrapInput>
      <WrapInput {...fieldProps}>
        <LabelInput source={'labelIds'} />
      </WrapInput>
    </Box>
  );
};

export default AdminSection;
