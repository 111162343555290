import * as React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';

interface Props {
  icon: React.ReactNode;
  title: React.ReactNode;
  children: React.ReactNode;
}

const Accordion = ({ icon, title, children }: Props) => {
  const classes = useStyles();
  return (
    <MuiAccordion defaultExpanded={true}>
      <AccordionSummary classes={{ root: classes.summary, expanded: classes.expanded }} expandIcon={icon}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

const useStyles = makeStyles(theme => ({
  summary: {
    backgroundColor: theme.palette.grey[200],
    minHeight: '36px !important',
  },
  expanded: {
    margin: '0 !important',
  },
}));

export default Accordion;
